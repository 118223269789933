import { BeanManager } from '../../system/services/system/bean.manager';

export const MondayApi = BeanManager.register(
  class MondayApi {
    findIssueWithCommentsById: any;
    findIssueByFilter(payload: any) {
      throw new Error('Method not implemented.');
    }
    findIssuesByJQL(payload: any) {
      throw new Error('Method not implemented.');
    }
    findIssueById(id: any) {
      throw new Error('Method not implemented.');
    }
    getAllGroups(): any {
      return [];
    }
  }
);
