export default {
  id: 'embed',
  name: 'IFrame Embed',
  description: `The iFrame Html Template is a pre-built template that allows users to easily embed any external website or web application . It provides a simple and intuitive interface for specifying the URL and size of the iFrame, and includes features such as responsive sizing and scrolling to ensure that the iFrame fits seamlessly into any page layout`,
  category: 'Misc / IFrame',
  tags: ['iframe', 'embed', 'external', 'website'],
  active: true,
  icon: 'fa-solid fa-map-location-dot',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe width="100%" height="100%" title="Title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
  script: `/** JavaScript */
$template.ready(async () => {
    const iframe = document.getElementsByTagName('iframe')[0];
    const variables = $template.useVariables(); // get variables service
    const url = await variables.getValue('url');
    iframe.src = url;
});`,
  css: ``,
  variables: [
    {
      label: 'Embed URL',
      name: 'url',
      type: 'multilineString',
      defaultFieldValue: 'https://example.com',
      mandatory: true,
      description: 'Embed URL',
      defaultVariable: true
    }
  ]
};
