import { useEffect, useMemo, useState } from 'react';
import { MacroRenderer } from './macro.renderer';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { TemplateConfigService } from '../../../services/template.config.service';
import { ForgeRuntime } from '../../../../forge/services/forge.runtime';
import { AppRuntime } from '../../../../vendor/services/app.runtime';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { ITemplateConfig } from '../../../model/itemplate.config';
const templateConfigService = TemplateConfigService.instance();
const forgeRuntime = ForgeRuntime.instance();
export function DashboardWidgetMacroRenderer() {
  const [configView, setConfigView] = useState(false);
  const [templateConfig, setTemplateConfig] = useState<ITemplateConfig>({});
  const [gadgetId, setGadgetId] = useState('');
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  useAsyncEffect(async () => {
    const context = await forgeRuntime.getContext();
    const gId = context.extension.gadget.id;
    setGadgetId(gId);
    const config = await templateConfigService.getWidgetTemplateConfig(gId);
    setTemplateConfig(config);
    if (await forgeRuntime.isRunningInForge()) {
      setConfigView(context.extension.entryPoint === 'edit');
      const { events } = await import('@forge/bridge');
      const subscription = events.on('JIRA_DASHBOARD_GADGET_REFRESH', async (payload) => {
        const { view } = await import('@forge/bridge');
        const context = await view.getContext();
        setConfigView(context.extension.entryPoint === 'edit');
      });
      return () => {
        subscription.then(({ unsubscribe }) => unsubscribe());
      };
    } else {
      setConfigView(searchParams.get('configView') === 'true');
    }
  }, []);
  useEffect(() => {
    if (configView) {
      document.body.classList.remove('dashboard-gadget-render-view');
      document.body.classList.add('dashboard-gadget-config-view');
    } else {
      document.body.classList.remove('dashboard-gadget-config-view');
      document.body.classList.add('dashboard-gadget-render-view');
      AppRuntime.instance().setBodyHeight(templateConfig.height || 500);
    }
  }, [configView]);
  return (
    <div className="dashboard-macro-renderer">
      <MacroRenderer
        hideConfigButton={true}
        openConfigView={configView}
        templateConfig={templateConfig}
        onApplyConfig={async (config) => {
          await templateConfigService.saveWidgetTemplateConfig(gadgetId, config);
          setTemplateConfig(config);
          setConfigView(false);
        }}
      />
    </div>
  );
}
