import { env } from '../../../env';
import { BeanManager } from '../services/system/bean.manager';
import { ReflectionUtil } from '../utils/reflection.util';

const logger = console;

export const KeyValStore = BeanManager.register(
  class KeyValStore {
    async get(key: string, parse = true) {
      const result = await fetch(`${env.UPSTASH_REDIS_REST_URL}`, {
        method: 'POST',
        body: JSON.stringify(['GET', key]),
        headers: {
          Authorization: `Bearer ${env.UPSTASH_REDIS_REST_TOKEN}`
        }
      });
      const json = await result.json();
      const value = json.result;
      if (parse) {
        return ReflectionUtil.safeParse(value, value);
      }
      return value;
    }
    async set(key: string, value: string) {
      const result = await fetch(`${env.UPSTASH_REDIS_REST_URL}`, {
        method: 'POST',
        body: JSON.stringify(['SET', key, JSON.stringify(value)]),
        headers: {
          Authorization: `Bearer ${env.UPSTASH_REDIS_REST_TOKEN}`
        }
      });
      const json = await result.json();
      return json;
    }
  }
);
