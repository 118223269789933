import { BeanManager } from '../../system/services/system/bean.manager';
import { AppExecuteCommands, DataStorage, RuntimeMock } from '../mock/app.mock';
import { env } from '../../../env';
import { ReflectionUtil } from '../../system/utils/reflection.util';
import { ITemplateConfig } from '../../advance-html/model/itemplate.config';
import { AppStorage } from './app.storage';
import { ForgeRuntime } from '../../forge/services/forge.runtime';
export enum StorageLevel {
  GLOBAL = 'global',
  INSTANCE = 'instance'
}
export interface SessionJWT {
  slug: string;
  client_id: string;
  user_id: string;
  account_id: string;
  app_id: string;
  app_version_id: string;
  instance_id: string;
  is_admin: boolean;
  is_guest: boolean;
  is_view_only: boolean;
  user_kind: 'admin' | 'guest' | 'view_only';
}
export interface FeatureModalProps {
  url?: string;
  urlPath?: string;
  urlParams?: Record<string, any>;
  height?: number | string;
  width?: number | string;
}
export const AppRuntime = BeanManager.register(
  class AppRuntime extends ForgeRuntime {
    openFeatureModal(props: { urlPath: string; width: number; height: number }) {
      // throw new Error('Method not implemented.');
      return null;
    }
    protected parsedJwt: { dat: SessionJWT; exp: number };

    getBaseUrl(): string {
      return window.location.origin;
    }
    getFullPageUrl(pagePath: string): string {
      return `${this.getBaseUrl()}/#${pagePath}`;
    }

    sendValueCreatedForUser() {
      try {
        //  return this.getNativeRuntime().execute('valueCreatedForUser');
      } catch (e) {
        console.error('error while value created', e);
      }
    }

    async getContextUser() {
      const result = await super.getContext();
      return result.accountId;
    }

    async loadTargetContext() {
      return await this.getContext();
    }

    getAppId(): string {
      return env.APP_ID;
    }
    generateKey(key: string) {
      return `${this.getAppId()}:${key}`;
    }

    updateUIStyle(config: ITemplateConfig) {
      document.body.style.setProperty('overflow', 'hidden');
      // if (config.styleSettings) {
      //   for (let key in config.styleSettings) {
      //     document.body.style.setProperty(key, config.styleSettings[key]);
      //   }
      // }
      if (config.enableFullScreen) {
        document.body.classList.remove('disable-full-screen');
        document.body.classList.add('enable-full-screen');
      } else {
        document.body.classList.remove('enable-full-screen');
        document.body.classList.add('disable-full-screen');
      }
    }

    getRouter() {
      return {
        open: (url: string) => {
          if (this.isRunningInIframe()) {
            //TODO: Open in jira
          } else {
            window.open(url, '_blank');
          }
        }
      };
    }
    isRunningInIframe() {
      return window.parent !== window;
    }
  }
);
