import { AppRuntime } from '../../vendor/services/app.runtime';
import { PanpalEventHandler } from '../../system/services/messaging/panpal.event.handler';
const runtime = AppRuntime.instance();
export class TemplateConfigEventHandler implements PanpalEventHandler {
  constructor(private context: any) {}
  sortOrder: number;
  getEventNames(): string[] {
    return ['config.get', 'config.getHeight'];
  }
  async handle(event: string, payload: any): Promise<any> {
    switch (event) {
      case 'config.get':
        return await runtime.getAppId();
      case 'config.getHeight':
        return await runtime.getAppId();
      default:
        return new Error('No handler found for event ' + event);
    }
  }
}
