import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Icon, {
  LayoutTwoTone,
  LayoutFilled,
  ThunderboltOutlined,
  ThunderboltFilled,
  SettingTwoTone,
  CloudUploadOutlined,
  ArrowLeftOutlined,
  SaveOutlined,
  LeftSquareTwoTone
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Breadcrumb, Button, Drawer, Dropdown, Modal, Select, Space, Switch, Tooltip } from 'antd';
import { CodeEditorInfoForm } from './CodeEditorInfoForm';
import { useNavigate } from 'react-router-dom';
import { InlineEdit } from '../../../system/components/input/inline.edit';
import { TemplateService } from '../../services/template.service';
import { NotificationService } from '../../../system/services/ui/notification.service';
export interface CodeEditorIDEMenuProps {
  settings?: any;
  onSettingChange?: (settings: any) => void;
  onMenuItemClick?: (item: any) => void;
  record?: any;
  onDetailsChange?: (record: any) => void;
  actions?: any;
  headerActions?: any;
}
export const CodeEditorIDEMenu = forwardRef<any, CodeEditorIDEMenuProps>(
  (
    {
      settings,
      onSettingChange,
      onMenuItemClick,
      record,
      onDetailsChange,
      actions,
      headerActions
    }: CodeEditorIDEMenuProps,
    ref
  ) => {
    onSettingChange = onSettingChange || function () {};
    onMenuItemClick = onMenuItemClick || function () {};
    onDetailsChange = onDetailsChange || function () {};
    settings = settings || {
      autoExecute: false,
      layout: { left: true, right: true, bottom: false }
    };
    useImperativeHandle(ref, () => ({
      openInfoModal(display = true) {
        showInfoModal(display);
      }
    }));
    const navigate = useNavigate();
    const [info, setInfo] = useState(record);
    const [openInfoModal, showInfoModal] = useState(false);
    const [isInfoValid, setIsInfoValid] = useState(false);
    /** @type {any}*/
    const detailsFormRef = useRef(null);
    useEffect(() => {
      setInfo(record);
    }, [record]);
    return (
      <div className="menu-component" style={{ paddingBottom: '5px', display: 'flex' }}>
        <Space className="left-menu" style={{ display: 'flex' }}>
          <Tooltip title="Back to templates">
            <Button
              disabled={settings.hideBackButton}
              className="back-btn"
              type="link"
              size="large"
              icon={<i className="fa fa-arrow-circle-left"></i>}
              onClick={() => {
                navigate('/admin-page');
              }}></Button>
          </Tooltip>
          <InlineEdit
            allowEdit={true}
            value={record.name || 'New'}
            onSave={(value) => {
              const changes = { ...info, name: value };
              setInfo(changes);
              onDetailsChange(changes);
            }}></InlineEdit>
        </Space>
        <Drawer
          title="Settings"
          open={openInfoModal}
          width={500}
          onClose={() => showInfoModal(false)}
          extra={
            <Space>
              <Button onClick={() => showInfoModal(false)}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => {
                  detailsFormRef?.current?.submitForm();
                }}>
                OK
              </Button>
            </Space>
          }>
          <CodeEditorInfoForm
            ref={detailsFormRef}
            record={info}
            onSubmit={(formRecord, isValid) => {
              setIsInfoValid(isValid);
              const changes = { ...info, ...formRecord };
              setInfo(changes);
              if (isValid) {
                onDetailsChange(changes);
                showInfoModal(false);
              }
            }}></CodeEditorInfoForm>
        </Drawer>
        <Space
          className="all-code-box-controls"
          style={{
            position: 'absolute',
            top: '5px',
            right: '10px',
            zIndex: 1,
            display: 'flex',
            fontSize: '22px'
          }}>
          {headerActions}
          <Space className="execute-config">
            <span style={{ fontSize: '14px' }}>Auto Execute</span>
            <Switch
              checked={settings.autoExecute}
              onChange={(value) => {
                onSettingChange({ ...settings, autoExecute: value });
              }}></Switch>
            <Button
              disabled={settings.autoExecute}
              type="primary"
              icon={<ThunderboltFilled />}
              onClick={() => onMenuItemClick('execute')}>
              Run
            </Button>
          </Space>
          {/* <Tooltip title={'Toggle Info'}>
            <span
              className={`left-layout-toggle layout-toggle ${
                settings.layout.left ? 'visible' : 'hidden'
              }`}
              onClick={() => {
                onSettingChange({
                  ...settings,
                  layout: { ...settings.layout, left: !settings.layout.left }
                });
              }}>
              <LayoutTwoTone className="outlined" />
              <LayoutFilled className="filled" />
            </span>
          </Tooltip> */}
          <Tooltip title={'Toggle Console'}>
            <span
              className={`bottom-layout-toggle layout-toggle ${
                settings.layout.bottom ? 'visible' : 'hidden'
              }`}
              onClick={() => {
                onSettingChange({
                  ...settings,
                  layout: { ...settings.layout, bottom: !settings.layout.bottom }
                });
              }}>
              <LayoutTwoTone className="outlined rotate-180" />
              <LayoutFilled className="filled rotate-180" />
            </span>
          </Tooltip>
          <Tooltip title={'Toggle Preview'} placement="bottomRight">
            <span
              className={`right-layout-toggle layout-toggle ${
                settings.layout.right ? 'visible' : 'hidden'
              }`}
              onClick={() => {
                onSettingChange({
                  ...settings,
                  layout: { ...settings.layout, right: !settings.layout.right }
                });
              }}>
              <LayoutTwoTone className="outlined invert" />
              <LayoutFilled className="filled invert" />
            </span>
          </Tooltip>
          {actions}
          <Tooltip title={'Settings'} placement="bottomRight">
            <Button
              type="link"
              style={{ fontSize: '22px' }}
              icon={<i className="fa fa-cog"></i>}
              onClick={(e) => {
                e.preventDefault();
                showInfoModal(true);
              }}></Button>
          </Tooltip>
          {/* <span>Theme</span> <Select  options={[{ label: '', value: '' }]}></Select> */}
        </Space>
      </div>
    );
  }
);

CodeEditorIDEMenu.propTypes = {
  settings: PropTypes.any,
  onSettingChange: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  record: PropTypes.any,
  onDetailsChange: PropTypes.func,
  actions: PropTypes.any,
  headerActions: PropTypes.any
};
CodeEditorIDEMenu.displayName = 'CodeEditorIDEMenu';
