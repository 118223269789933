export default {
  name: 'CodePen Embed',
  description: 'Embed a CodePen pen on a webpage.',
  category: 'Code / CodePen',
  tags: ['code', 'codepen'],
  active: true,
  icon: 'fa-solid fa-code',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `
      <div id="embedContainer" style="width: 100%; height: 100%;">
        <iframe style="width: 100%; height: 100%;" scrolling="no" title="CodePen Embed" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true"></iframe>
        <div id="error" style="display: none; color: red;"></div>
      </div>`,
  script: `/** JavaScript */
    $template.ready(async () => {
      const variables = $template.useVariables();
      const penUrl = await variables.getValue('CodePen_Pen_URL');
      const iframeElement = document.querySelector('iframe');
      const errorElement = document.getElementById('error');
  
      if (penUrl) {
        iframeElement.src = penUrl;
        errorElement.style.display = 'none';
      } else {
        // Invalid CodePen pen URL handling
        errorElement.textContent = 'Invalid CodePen Pen URL: ' + penUrl;
        errorElement.style.display = 'block';
      }
    });`,
  css: `
      #error {
        font-size: 16px;
        padding: 10px;
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
        border-radius: 4px;
        margin: 10px;
      }`,
  variables: [
    {
      label: 'CodePen Pen URL',
      name: 'CodePen_Pen_URL',
      type: 'multilineString',
      defaultFieldValue: 'https://codepen.io/team/codepen/embed/PNaGbb',
      mandatory: true,
      description: 'CodePen Pen URL',
      defaultVariable: true
    }
  ]
};
