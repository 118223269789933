import { AppRuntime } from '../../../vendor/services/app.runtime';
import { BeanManager } from '../system/bean.manager';
import ReactGA from 'react-ga4';
import { CookieService } from './cookie.service';

const cookieService = CookieService.instance();

export const GtmService = BeanManager.register(
  class GtmService {
    protected customerId: string;
    enabled = false;
    enable(): this {
      this.enabled = true;
      return this;
    }
    disable(): this {
      this.enabled = false;
      return this;
    }
    shouldTrack(): boolean {
      return this.enabled && cookieService.isAnalyticsEnabled();
    }
    async init(trackingId: string) {
      if (!this.shouldTrack()) {
        return;
      }
      this.customerId = AppRuntime.instance().getBaseUrl();
      ReactGA.initialize([
        {
          trackingId,
          gaOptions: {
            userId: this.customerId,
            user_id: this.customerId,
            cookieFlags: 'SameSite=None;Secure'
          } // optional
        }
      ]);
    }
    pageview(path: string) {
      if (!this.shouldTrack()) {
        return;
      }
      const page = '/' + this.customerId + path;
      // const page = path;
      this.send({ hitType: 'pageview', page, title: page });
    }
    send(params = {}) {
      if (!this.shouldTrack()) {
        return;
      }
      if (this.enabled) {
        ReactGA.send(params);
      }
    }
  }
);
