import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { CodeEditorIDE } from '../../ide/CodeEditorIDE';
import React from 'react';
import { Button } from 'antd';
import { AppRuntime } from '../../../../vendor/services/app.runtime';
import { TemplateService } from '../../../services/template.service';

const mondayRuntime = AppRuntime.instance();
const templateService = TemplateService.instance();
export default function MacroConfigPopup() {
  /**@type {any} */
  const ideRef = useRef(null);
  const [record, setRecord] = useState(templateService.emptyTemplate());
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      if (await mondayRuntime.isRunningInIframe()) {
        // const data = await templateService.getTemplateConfig();
        // if (id !== 'new') {
        //   const fetchedRecord = templateService.instance().getTemplateById(id);
        //   setRecord(fetchedRecord);
        // }
      }
    })();
  }, []);

  return (
    <CodeEditorIDE
      hideBackButton={false}
      templateLoaded={true}
      headerActions={[]}
      cspSettings={{ enableCSP: false, policies: [] }}
      onDetailsChange={(record) => setRecord(record)}
      onMenuItemClick={(item) => console.log(item)}
      readOnly={false}
      ref={ideRef}
      record={record}
      onChange={(record) => setRecord(record)}
      actions={
        <div className="form-action">
          <Button type="primary" onClick={() => console.log(record)}>
            Save
          </Button>
          <Button>Cancel</Button>
        </div>
      }></CodeEditorIDE>
  );
}
