import React, { useState } from 'react';
import { Form, Checkbox, Button, Card, Input, Select, Radio, Segmented, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { ITemplateConfig } from '../../../model/itemplate.config';
import { StyleComponent } from './StyleComponent';
import { AppRuntime } from '../../../../vendor/services/app.runtime';
import { ReactIf } from '../../../../system/components/conditional/r.if';
import { useAsync } from 'react-use';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { ForgeContext, ForgeRuntime } from '../../../../forge/services/forge.runtime';
import { ITemplateEntity } from '../../../model/itemplate';

const runtime = ForgeRuntime.instance();

export interface IOtherConfigurationsProps {
  config: ITemplateConfig;
  onChange: (config: ITemplateConfig) => void;
  template: ITemplateEntity;
  onInit: (form: any) => void;
}

export function OtherConfigurations({
  config,
  onChange,
  template,
  onInit
}: IOtherConfigurationsProps) {
  const [context, setContext] = useState<ForgeContext>({ extension: {} });
  const [issueTypes, setIssueTypes] = useState<any[]>([]);
  useAsyncEffect(async () => {
    const context = await runtime.getContext();
    setContext(context);
    setIssueTypes(await runtime.getIssueTypes());
  }, []);
  const [form] = Form.useForm<ITemplateConfig>();
  onInit(form);
  const applyOnIssues = Form.useWatch('applyOnIssues', form);
  return (
    <Form
      form={form}
      name="config_form"
      layout="horizontal"
      initialValues={{ ...config }}
      onChange={(e) => {
        onChange({
          ...form.getFieldsValue()
        });
      }}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}>
      <ReactIf condition={context.extension.type !== 'jira:dashboardGadget'}>
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input placeholder="Enter title"></Input>
        </Form.Item>
      </ReactIf>
      <ReactIf condition={context.extension.type === 'jira:issuePanel'}>
        <Form.Item name="applyOnIssues" label="Show on" rules={[{ required: true }]}>
          <Select
            options={[
              { label: 'This Issue', value: 'current' },
              { label: 'All Issues in this Project', value: 'all' },
              { label: 'Custom Condition', value: 'custom' }
            ]}
            onChange={(value) => {
              form.setFieldsValue({ jql: '' });
              onChange({
                ...form.getFieldsValue()
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Issue Types"
          name="issueTypes"
          style={{ display: applyOnIssues === 'all' ? 'block' : 'none' }}
          rules={[{ required: applyOnIssues === 'all' }]}>
          <Select
            mode="multiple"
            options={issueTypes.map((type) => ({
              label: (
                <Space>
                  <img src={type.iconUrl} />
                  <span>{type.name}</span>
                </Space>
              ),
              value: type.name
            }))}
            onChange={(value) => {
              onChange({
                ...form.getFieldsValue()
              });
            }}></Select>
        </Form.Item>
        <Form.Item
          label="Enter JQL"
          style={{ display: applyOnIssues === 'custom' ? 'block' : 'none' }}
          name="jql"
          rules={[{ required: applyOnIssues === 'custom' }]}>
          <Input.TextArea placeholder="Enter JQL" rows={2}></Input.TextArea>
        </Form.Item>
      </ReactIf>
      <ReactIf
        condition={['jira:dashboardGadget', 'jira:issuePanel'].indexOf(context.extension.type) < 0}>
        <Form.Item
          name="enableConfigAction"
          valuePropName="checked"
          label={<span>Show Config Button</span>}>
          <Checkbox></Checkbox>
        </Form.Item>
      </ReactIf>
      <ReactIf condition={context.extension.type === 'jira:dashboardGadget'}>
        <Form.Item
          name="styleSettings.height"
          valuePropName="styleSettings.height"
          label={<span>Height</span>}>
          <Input placeholder="Enter height" type="number"></Input>
        </Form.Item>
      </ReactIf>
      <Form.Item
        name="enableFullScreen"
        valuePropName="checked"
        label={<span>Show Full Screen Button</span>}>
        <Checkbox></Checkbox>
      </Form.Item>
    </Form>
  );
}
