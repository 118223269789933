export default {
  name: 'Google Docs',
  description: 'Embed a Google Docs document .',
  category: 'Google / Docs',
  tags: ['document', 'google docs'],
  active: true,
  icon: 'fa-solid fa-file-alt',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe src="" width="100%" height="100%" frameborder="0"></iframe>`,
  script: `$template.ready(async () => {
    const iframe = document.getElementsByTagName('iframe')[0];
    const variables = $template.useVariables();
    const url = await variables.getValue('Your_Document_URL');
    iframe.src = url;
  });  
  `,
  css: ``,
  variables: [
    {
      label: 'Document URL',
      name: 'Your_Document_URL',
      type: 'multilineString',
      defaultFieldValue:
        'https://docs.google.com/document/d/17MzHt9aiNfdqk_rSPVdzn7GRWgdJUd9OR3y8lmfY12M/edit?usp=sharing',
      mandatory: true,
      description: 'Google Docs Document ID',
      defaultVariable: true
    }
  ]
};
