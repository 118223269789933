import { NotificationService } from '../../system/services/ui/notification.service';

export class ForgeApiError extends Error {
  constructor(message: string, public response: ForgeResolverApiResponse<any>) {
    super(message);
  }
}
/***
 * @template T
 */
export class ForgeResolverApiResponse<T> {
  public contents: T[] | T;
  public error: Error;
  public status = 200;
  public message = '';
  public etag: number;
  constructor(forgeResponse: Partial<ForgeResolverApiResponse<T>>) {
    Object.assign(this, forgeResponse);
  }
  parse({ showErrorNotification = false }) {
    if (this.error || this.status > 299) {
      if (showErrorNotification) {
        this.showErrorNotification();
      }
      console.error(`Forge API Error: ${this.error} with status ${this.status}`, this);
      const error = new ForgeApiError(`Forge API Error: ${this.error}`, this);
      throw error;
    }
    return this;
  }
  getStatus() {
    return this.status;
  }
  withContents(contents: T[] | T) {
    this.contents = contents;
    return this;
  }
  withError(error: Error) {
    this.error = error;
    return this;
  }
  withStatus(status: number) {
    this.status = status;
    return this;
  }
  showErrorNotification() {
    let message = this.message;
    if (!message) {
      switch (this.status) {
        case 400:
          message = 'Bad request';
          break;
        case 401:
          message = 'Resource not found';
          break;
        case 403:
          message = 'You are not authorized to access this resource';
          break;
        case 404:
          message = 'Resource not found';
          break;
        default:
          message = 'Something went wrong, please try again later';
          break;
      }
    }
    NotificationService.instance().error({
      message
    });
    return this;
  }
}
