import { env } from '../../../env';

const forgeContext = {
  localId:
    // eslint-disable-next-line max-len
    'ari:cloud:ecosystem::extension/c0ae4949-223a-45b6-b285-c6a49af5b062/e8c1218a-de7e-4f27-b465-31e66faa268c/static/html-template-jira-hello-world-issue-panel-bc7e8565-1698234819288',
  cloudId: '8e674e72-6b4a-415c-a379-96a52052e909',
  environmentId: 'e8c1218a-de7e-4f27-b465-31e66faa268c',
  environmentType: 'DEVELOPMENT',
  moduleKey: 'html-template-jira-hello-world-issue-panel',
  siteUrl: 'https://dev-appknitters.atlassian.net',
  extension: {},
  accountId: '712020:7c496059-62b9-4a1c-96a4-6ae6af1f10ed',
  license: null,
  timezone: 'Asia/Calcutta',
  locale: 'en_US',
  theme: {}
};
export const MockContextData = {
  projectView: {
    ...forgeContext,
    context: {
      project: {
        id: '10000',
        key: 'DEV',
        type: 'business'
      },
      type: 'project'
    }
  },
  issueView: {
    ...forgeContext,
    extension: {
      issue: {
        key: 'DEV-1',
        id: '10000',
        type: 'Task',
        typeId: '10001'
      },
      project: {
        id: '10000',
        key: 'DEV',
        type: 'business'
      },
      isNewToIssue: false,
      type: 'jira:issuePanel'
    }
  },
  dashboardGadget: {
    ...forgeContext,
    extension: {
      gadget: { id: '10000' },
      dashboard: { id: '10000' },
      gadgetConfiguration: { id: '10000' },
      type: 'jira:dashboardGadget',
      entryPoint: 'edit'
    }
  },
  notification: {
    ...forgeContext,
    notificationId: 64564519872, // unique notification ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'notification' // feature type
  },
  workspace: {
    ...forgeContext,
    workspaceId: 64564519872, // unique workspace ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'workspace' // feature type
  },
  account: {
    ...forgeContext,
    accountId: 64564519872, // unique account ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'account' // feature type
  },
  user: {
    ...forgeContext,
    userId: 64564519872, // unique user ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'user' // feature type
  }
};
