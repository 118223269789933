export interface ITemplateConfig {
  height?: number;
  issueId?: any;
  projectId?: any;
  extensionType?: string;
  id?: string;
  title?: string;
  enableFullScreen?: boolean;
  includeExamples?: boolean;
  styleSettings?: React.CSSProperties;
  variables?: Record<string, any>;
  templateId?: string;
  templateName?: string;
  enableConfigAction?: boolean;
  applyOnIssues?: string;
  issueTypes?: string[];
  jql?: string;
}
export const DEFAULT_TEMPLATE_CONFIG: ITemplateConfig = {
  styleSettings: {
    overflow: 'hidden'
  },
  enableConfigAction: true,
  enableFullScreen: true,
  variables: [],
  applyOnIssues: 'current',
  issueTypes: []
};
