import React, { useEffect, useState } from 'react';
import { FormInstance, Popover, Typography, message, notification } from 'antd';
import { Form, Input, Select, Checkbox, Button, Card, Switch, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined, ClearOutlined } from '@ant-design/icons';
import {
  FormItemByTypeRenderer,
  VariableType,
  VariableTypeComponentsMapping
} from './FormItemByTypeRenderer';
import { ReactIf } from '../../../system/components/conditional/r.if';
import { NotificationService } from '../../../system/services/ui/notification.service';
import { CopyToClipboard } from '../../../system/components/utils/copy.to.clipboard';
const { Text, Link } = Typography;

const { Option } = Select;

interface VariableFormProps {
  variable?: Variable;
  onSubmit: (variable: Variable) => void;
  onCancel: (variable: Variable) => void;
  onInit: (form: FormInstance<Variable>) => void;
  mdode: 'create' | 'edit';
}

export interface VariableSettings {
  language?: string;
  multiLine?: boolean;
  rows?: number;
  cols?: number;
  min?: number;
  max?: number;
  regex?: string;
  validator?: string;
  height?: number;
}
export interface Variable {
  choices?: { label: string | number; value: string | number }[];
  value?: any;
  name: string;
  label: string;
  type: VariableType;
  description?: string;
  defaultFieldValue?: any;
  mandatory: boolean;
  defaultVariable: boolean;
  settings: VariableSettings;
}

const VariableForm: React.FC<VariableFormProps> = ({
  variable = {
    name: '',
    label: '',
    type: 'string',
    description: '',
    defaultFieldValue: [],
    mandatory: false,
    defaultVariable: false,
    settings: {}
  },
  onSubmit,
  onCancel,
  onInit,
  mdode
}: VariableFormProps) => {
  const [form] = Form.useForm<Variable>();
  onInit(form);
  const [type, setType] = useState<VariableType>(variable.type);
  useEffect(() => {
    setType(variable.type);
  }, [variable.type]);
  const handleSubmit = (event) => {
    // event.preventDefault();
    onSubmit(form.getFieldsValue());
  };
  const handleCancel = () => {
    onCancel(form.getFieldsValue());
  };
  const choices = Form.useWatch('choices', form);
  // const defaultFieldValue = Form.useWatch('defaultFieldValue', form);
  // useEffect(() => {
  //   console.log('choices', choices, defaultFieldValue);
  // }, [choices, defaultFieldValue]);
  return (
    <Form
      form={form}
      initialValues={variable}
      onFinish={handleSubmit}
      onFinishFailed={(error) => {
        // NotificationService.instance().error({
        //   message: error.errorFields[0].errors[0]
        // });
      }}
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}>
      <Form.Item label="Label" name="label" rules={[{ required: true }]}>
        <Input
          addonAfter={
            <Popover
              content="A variable is limited to containing only alphanumeric characters."
              title={
                <Space>
                  Info<i className="fa fa-circle-info"></i>
                </Space>
              }
              trigger="hover">
              <Link>
                <i className="fa fa-circle-info"></i>
              </Link>
            </Popover>
          }
          onChange={(e) => {
            if (e.target.value && e.target.value.length > 0) {
              const name =
                e.target.value[0].toLocaleLowerCase() +
                e.target.value
                  .substring(1)
                  .replace(/\s/g, '_')
                  .replace(/[^\w\d\_]/gi, '');
              form.setFieldsValue({
                name
              });
            }
          }}
          value={variable.name}
        />
      </Form.Item>
      <Form.Item label="Name" name="name">
        <Input
          value={variable.name}
          disabled
          addonAfter={
            <CopyToClipboard
              style={{ padding: '0', height: '15px', cursor: '' }}
              text={variable.name}
            />
          }
        />
      </Form.Item>
      <Form.Item label="Type" name="type" rules={[{ required: true }]}>
        <Select showSearch optionFilterProp="children" onSelect={(value) => setType(value)}>
          {Object.keys(VariableTypeComponentsMapping).map((type, index) => (
            <Option key={index} value={type}>
              {VariableTypeComponentsMapping[type]?.label || type}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input value={variable.description} />
      </Form.Item>
      <ReactIf condition={type === 'multichoice' || type === 'choice'}>
        <Form.Item
          label="Options"
          name="choices"
          rules={[
            { required: type === 'multichoice' || type === 'choice', message: 'Missing choices' }
          ]}>
          <Form.List name="choices">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'label']}
                      rules={[{ required: true, message: 'Missing label' }]}>
                      <Input placeholder="Label" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'value']}>
                      <Input placeholder="Value" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add options
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </ReactIf>
      <Form.Item label="Default Value" name="defaultFieldValue">
        <FormItemByTypeRenderer
          variable={{ ...variable, type, choices, settings: {}, value: variable.defaultFieldValue }}
          onChange={(changes) => {
            if (changes.target) {
              changes = changes.target.value;
            }
            form.setFieldValue('defaultFieldValue', changes);
          }}
        />
      </Form.Item>
      <Form.Item label="Mandatory" name="mandatory" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
            {mdode === 'create' ? 'Add' : 'Update'}
          </Button>
          <Button
            type="link"
            danger
            htmlType="reset"
            icon={<ClearOutlined />}
            onClick={handleCancel}>
            Reset
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default VariableForm;
