import { useState } from 'react';
import { ITemplateConfig } from '../../../model/itemplate.config';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { TemplateConfigService } from '../../../services/template.config.service';
import {
  Badge,
  Button,
  Col,
  Input,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { RowSelectionType } from 'antd/es/table/interface';
import { NotificationService } from '../../../../system/services/ui/notification.service';
import { ReactIf } from '../../../../system/components/conditional/r.if';
import { useQuery } from '@tanstack/react-query';
import { AclService } from '../../../services/acl.service';
import {
  PlusCircleFilled,
  DeleteFilled,
  EyeOutlined,
  ThunderboltOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  CheckOutlined,
  CopyOutlined,
  CloseOutlined,
  FormatPainterOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { StringUtil } from '../../../../system/utils/string.util';
import { useNavigate } from 'react-router-dom';
import { TemplateRenderer } from './TemplateRenderer';

const { Text, Link } = Typography;

const templateConfigService = TemplateConfigService.instance();
const aclService = AclService.instance();

export function TemplateConfigList() {
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();

  const [templateConfigs, setTemplateConfigs] = useState<ITemplateConfig[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]); // Check here to configure the default column
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [previewdId, setPreviewdId] = useState<string>();
  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const issueConfigs = await templateConfigService.getAllTemplateConfigs();
      setTemplateConfigs(issueConfigs);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);
  const getSelectedRows = () => {
    return selectedRowKeys.map((key) => {
      return templateConfigs.find((r) => r.id === key);
    });
  };

  const query = useQuery({
    queryKey: [templateConfigs, searchText, selectedType],
    queryFn: async () => {
      return templateConfigs.filter((r) => {
        let matched = true;
        if (searchText.toLocaleLowerCase().length > 0) {
          matched =
            r.title.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0 ||
            r.projectId.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0;
        }
        return matched;
      });
    }
  });

  const deleteTemplate = async (templateConfig: ITemplateConfig) => {
    /*const selectedTemplates = getSelectedRows();
    if (selectedTemplates.length === 0) return;
    if (selectedTemplates.length > 1) {
      NotificationService.instance().error({
        message: 'Please select only one template to delete'
      });
      return;
    }*/
    const selectedTemplate = templateConfig; // selectedTemplates[0];
    const result = await modal.confirm({
      title: 'Delete Template',
      content: (
        <div>
          Are you sure to delete
          <Text type="danger" keyboard>
            {selectedTemplate.title}
          </Text>
          ?
        </div>
      ),
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await templateConfigService.deleteTemplateConfig(selectedTemplate);
          setSelectedRowKeys([]);
          setTemplateConfigs(templateConfigs.filter((t) => t.id !== selectedTemplate.id));
          NotificationService.instance().success({ message: 'Configuration deleted successfully' });
        } catch (e) {
          console.error(e);
          NotificationService.instance().error({ message: 'Failed to delete template' });
        }
      }
    });
  };
  const rowSelection = {
    type: 'radio' as RowSelectionType,
    selectedRowKeys,
    onChange(newSelectedRowKeys) {
      // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'action',
      width: 40,
      render: (text, record, index) => (
        <Popover
          placement="topLeft"
          title={text}
          content={() => (
            <div style={{ width: '640px', height: '480px' }}>
              <TemplateRenderer id={record.templateId} config={record}></TemplateRenderer>
            </div>
          )}
          trigger="click">
          <Tooltip title="Preview">
            <a
              className={`preview-template-action ${previewdId === record.id ? 'active' : ''}`}
              onClick={(e) => {
                setPreviewdId(record.id);
                e.preventDefault();
              }}>
              <EyeOutlined />
            </a>
          </Tooltip>
        </Popover>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Template',
      dataIndex: 'templateName',
      key: 'templateName',
      render(text, record, i) {
        return (
          <Button type="link" onClick={() => navigate(`/templates/${record.templateId}/edit`)}>
            {text}
          </Button>
        );
      }
    },
    {
      title: 'Project',
      dataIndex: 'projectId',
      key: 'description'
    },
    {
      title: 'Target Entity',
      dataIndex: 'configType',
      key: 'configType',
      render(text, record, i) {
        return (
          <Tag color={StringUtil.getArrayItemByHash(['blue', 'green'], text)}>
            {text?.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: 'Apply on All',
      dataIndex: 'applyOnIssues',
      key: 'applyOnIssues',
      render(text, rec, i) {
        return text ? <CheckOutlined /> : <CloseOutlined />;
      }
    },
    {
      title: 'Target Entity Types',
      dataIndex: 'issueTypes',
      key: 'issueTypes',
      render(text, rec, i) {
        if (!text || text.length === 0) {
          return <Tag color="blue">All</Tag>;
        }
        return text.map((t, i) => {
          return (
            <Tag
              key={i}
              color={StringUtil.getArrayItemByHash(['blue', 'green', 'red', 'yellow'], t)}>
              {t}
            </Tag>
          );
        });
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button icon={<DeleteOutlined />} danger onClick={() => deleteTemplate(record)}></Button>
      )
    }
  ];
  return (
    <div className="list template-list">
      <div className="modal-context-holder">{contextHolder}</div>
      <div className="action-bar" style={{ padding: '10px' }}>
        <Row>
          <Col span={15}>
            <Spin spinning={aclService.isLoading()} indicator={<LoadingOutlined />}>
              {/* <ReactIf condition={aclService.isInitialized() && aclService.canEdit()}>
                <Space>
                  <Button
                    // ref={tourRefs.newButton}
                    type="primary"
                    icon={<PlusCircleFilled />}
                    onClick={() => {
                      // navigate('/templates/new/edit');
                    }}>
                    New
                  </Button>

                  <Button
                    // ref={tourRefs.deleteButton}
                    icon={<DeleteFilled />}
                    danger
                    disabled={
                      selectedType === 'example' ||
                      selectedRowKeys.length === 0 ||
                      selectedRowKeys.length > 1
                    }
                    onClick={deleteTemplate}>
                    Delete
                  </Button>
                  <Button
                    // ref={tourRefs.cloneButton}
                    disabled={selectedRowKeys.length === 0 || selectedRowKeys.length > 1}
                    icon={<CopyOutlined />}
                    onClick={() => {
                      // navigate(`/templates/new/edit?sourceId=${selectedRowKeys[0]}`)
                    }}>
                    Clone
                  </Button>
                </Space>
              </ReactIf> */}
            </Spin>
          </Col>
          <Col span={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Badge
              //ref={tourRefs.search}
              count={searchText.trim() !== '' ? 1 : 0}
              status="warning">
              <Space>
                {/* <Radio.Group
                value={selectedType}
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}>
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="example">Examples</Radio.Button>
              </Radio.Group> */}
                <ReactIf condition={selectedRowKeys.length === 1}>
                  <Tag color="blue" style={{ padding: '5px' }}>
                    {selectedRowKeys.length === 1 && getSelectedRows()[0].title}
                  </Tag>
                </ReactIf>
                <Input.Search
                  placeholder="search"
                  onSearch={(value) => {
                    setSearchText(value);
                  }}
                  style={{
                    width: 200
                  }}
                />
              </Space>
            </Badge>
          </Col>
        </Row>
      </div>

      <div className="grid">
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setSelectedRowKeys([record.id]);
              }, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {} // mouse leave row
            };
          }}
          loading={{ spinning: loading, tip: 'Loading...', indicator: <LoadingOutlined /> }}
          sticky={true}
          size="middle"
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={query.data || []}
          rowKey="id"
          scroll={{ y: 500 }}
          pagination={{
            defaultPageSize: 15,
            showQuickJumper: true,
            showTotal: (total) => `Total ${total} of ${query.data?.length} items`,
            showSizeChanger: true
          }}
        />
      </div>
    </div>
  );
}
