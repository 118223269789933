export default {
  name: 'Text Banner',
  description: `The Text Banner Html Template is a pre-built template that allows users to easily create and display a text banner .`,
  category: 'Misc / Text Banner',
  tags: ['misc', 'banner'],
  active: true,
  icon: 'fa-solid fa-text',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<div id="banner" class="changing-color">Hello World</div>`,
  script: `/** JavaScript */
  $template.ready(async () => {
    const banner = document.getElementById('banner');
    const variables = $template.useVariables(); // get variables service
    const text = await variables.getValue('text');
    banner.innerHTML = text;
  });`,
  css: `  @keyframes hue {
      from {
          filter: hue-rotate(0deg);
      }
  
      to {
          filter: hue-rotate(360deg);
      }
  }
  .changing-color {
      animation: hue 4s infinite linear;
      background-image: linear-gradient(92deg, #f35626 0%,#feab3a 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      color: #f35626;
      font-family: Trebuchet MS;
      font-size: 10rem;
      text-align: center;
  }`,
  variables: [
    {
      name: 'text',
      type: 'string',
      defaultFieldValue: 'Hello World',
      defaultVariable: true,
      mandatory: true,
      description: 'Text to display in the banner'
    }
  ]
};
