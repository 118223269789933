export default {
  name: 'Button',
  description: `The Button Html Template is a pre-built template that allows users to easily create and display a button .`,
  category: 'Misc / Button',
  tags: ['misc', 'button'],
  active: true,
  icon: 'fa-solid fa-btn',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<button id="button" class="button">Click Me</button>`,
  script: `/** JavaScript */
  $template.ready(async () => {
      const button = document.getElementById('button');
      const variables = $template.useVariables(); // get variables service
      const text = await variables.getValue('text');
      const action = await variables.getValue('onClick');
      button.innerHTML = text;
      button.onclick = () => {
        eval(action); // execute the onClick script
      }
  });`,
  css: `button {
    display: inline-block;
    padding: 10px 20px;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #0052cc;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .atlassian-button:hover {
    background-color: #003580;
  }`,
  variables: [
    {
      label: 'Text',
      name: 'text',
      type: 'string',
      defaultFieldValue: 'Click Me!',
      mandatory: true,
      description: 'Text to display in the banner'
    },
    {
      label: 'On Click',
      name: 'onClick',
      type: 'script',
      defaultFieldValue: 'alert("Hello World")',
      mandatory: true,
      description: 'Script to execute onClick'
    }
  ]
};
