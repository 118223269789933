/* eslint-disable quotes */
import { ForgeResolverApi } from '../../forge/api/forge.resolver.api';
import { ForgeResolverApiResponse } from '../../forge/api/forge.resolver.api.response';
import { ForgeStorageQuery } from '../../forge/api/forge.storage.query';
import { ForgeUserApi } from '../../forge/api/forge.user.api';
import { BeanManager } from '../../system/services/system/bean.manager';
import { TableNames } from '../model/table.names';

const keyValStorageQuery = new ForgeStorageQuery<{ key: string; value: any }>(
  TableNames.KeyValueStore
);
export interface AppSettings {
  excludeExamples?: boolean;
}
export const PermissionService = BeanManager.register(
  class PermissionService {
    protected contentPermissionExists = false;

    constructor() {}
    async getContentPolicies() {
      const cKeyVal = await keyValStorageQuery.findById('contentPolicies', {
        cached: false
      });
      if (cKeyVal && cKeyVal.value) {
        this.contentPermissionExists = true;
        return cKeyVal.value;
      }
      return [];
    }
    /***CSP Example
     * {
  "policies": [
    {
      "url": "https://b96c-103-69-38-20.in.ngrok.io/connect/atlassian-connect.json",
      "default-src": true,
      "frame-src": true
    },
    {
      "url": "https://f6c2-103-69-38-20.in.ngrok.io/connect/atlassian-connect.json"
    },
    {
      "url": "https://69a4-103-69-38-20.in.ngrok.io/connect/atlassian-connect.json",
      "default-src": true,
      "frame-src": true,
      "frame-ancestors": true
    }
  ],
  "enableCSP": false
}
     */
    cspListToMap(cspList) {
      const cspMap = {
        'default-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'script-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'style-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'img-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'font-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'object-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'media-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'frame-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:']
      };
      for (const policy of cspList) {
        for (const key in policy) {
          if (key !== 'url' && policy[key] === true) {
            if (!cspMap[key]) {
              cspMap[key] = [];
            }
            cspMap[key].push(policy['url']);
          }
        }
      }

      return cspMap;
    }
    saveContentPolicies({ enableCSP = false, policies }) {
      if (this.contentPermissionExists) {
        return keyValStorageQuery.update({
          id: 'contentPolicies',
          value: { policies, enableCSP }
        });
      }
      return keyValStorageQuery.create({
        id: 'contentPolicies',
        value: { policies, enableCSP }
      });
    }
    async saveSettings(settings: AppSettings) {
      return keyValStorageQuery.create({
        id: 'appSettings',
        value: settings
      });
    }
    async getSettings(): Promise<AppSettings> {
      const settingsPair = await keyValStorageQuery.findById('appSettings', {
        cached: false
      });
      return settingsPair?.value || {};
    }
  }
);
