import { BeanManager } from '../../system/services/system/bean.manager';
import { env } from '../../../env';
import { ForgeResolverApiResponse } from './forge.resolver.api.response';
import { ConnectRuntime } from '../../connect/services/connect.runtime';
const FORGE_CONTROLLER_RESOLVER = 'forge-controller-resolver';
export interface ResolverInvokeOptions {
  showErrorNotification?: boolean;
}
const connectRuntime = ConnectRuntime.instance();
export const ForgeResolverApi = BeanManager.register(
  'ForgeResolverApi',
  class {
    async getEndpoint() {
      if (await connectRuntime.isRunningInConnect()) {
        return await connectRuntime.getWebtriggerUrl();
      }
      // eslint-disable-next-line no-undef
      return env.MOCK_ENDPOINT || 'http://localhost:3000';
    }
    async invokeForge(route: string, payload: Record<string, any>) {
      const { invoke } = await import('@forge/bridge');
      return invoke(FORGE_CONTROLLER_RESOLVER, { ...payload, route });
    }
    async invokeEndpoint(endpoint: string, route: string, payload: Record<string, any>) {
      const result = await fetch(`${endpoint}/resolvers/${route}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ payload: { ...payload, route } })
      });
      return result.json();
    }
    async invokeConnectForge(route: string, payload: Record<string, any>) {
      const endpoint = await connectRuntime.getWebtriggerUrl();
      const result = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ payload: { ...payload, route } })
      });
      return result.json();
    }
    async invokeLocal(route: string, payload: Record<string, any>) {
      return this.invokeEndpoint(env.MOCK_ENDPOINT || 'http://localhost:3000', route, payload);
    }
    /**
     * @param {string} route
     * @param {Record<string,any>} payload
     * @returns {Promise<ForgeResolverApiResponse>}
     */
    async invoke(
      route: string,
      payload: Record<string, any>,
      options: ResolverInvokeOptions = { showErrorNotification: false }
    ): Promise<ForgeResolverApiResponse<any>> {
      let response = null;
      // eslint-disable-next-line no-undef
      if (env.FORGE_ENV === 'mock') {
        response = await this.invokeLocal(route, payload);
      } else if (await connectRuntime.isRunningInConnect()) {
        response = await this.invokeConnectForge(route, payload);
      } else {
        response = await this.invokeForge(route, payload);
      }
      // console.log('ForgeResolverApi.invoke', route, payload, response);
      return new ForgeResolverApiResponse(response).parse(options);
    }
  }
);
