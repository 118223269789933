import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import { Drawer, message } from 'antd';
import htmlService from '../../services/html.service';
// @ts-ignore
import { ReflectionUtil } from '../../../system/utils/reflection.util';
import { EditorConsole } from './EditorConsole';
import { Allotment } from 'allotment';
import { CSPContext } from '../../contexts';
import { useAsyncEffect } from '../../../system/hooks/use.async.effect';
import { PermissionService } from '../../services/csp.service';
import { ConnectToIframe, RegisterPanpalHandlers } from '../../panpal-handlers';
import { ITemplateEntity } from '../../model/itemplate';
import _ from 'lodash';
import { useDebounce } from '../../../system/hooks/useDebounce';
import { AppRuntime } from '../../../vendor/services/app.runtime';
const permissionService = PermissionService.instance();

export interface IframeRendererProps {
  debounce?: number;
  template: ITemplateEntity;
  iframeRef?: any;
  onFrameContentRefresh?: () => void;
  autoExecute?: boolean;
  execute?: boolean;
  onExecute?: () => void;
  onConsoleMessage?: (msg: { args: any[]; level: string }) => void;
  view?: 'dev' | 'published';
  cspSettings: { enableCSP: boolean; policies: any[] };
  bodyStyle?: React.CSSProperties;
}
export default function IframeRenderer({
  template,
  cspSettings,
  iframeRef,
  onFrameContentRefresh,
  autoExecute,
  execute,
  onExecute,
  onConsoleMessage,
  view = 'dev',
  bodyStyle = {},
  debounce = 0
}: IframeRendererProps) {
  const invokeWithDebounce = useDebounce(debounce);
  // console.log('IframeRenderer', { template });
  onExecute = onExecute || function () {};
  onConsoleMessage = onConsoleMessage || function () {};
  /** @type {any} */
  const { html, css, javascript } = template;
  if (view === 'published') {
    // html = template.$;
  }
  const iframeParentDivRef = iframeRef || useRef(null);
  const splitPaneRef = useRef(null);
  // const [cspSettings, setCSPSettings] = useState({ enableCSP: false, policies: [] });

  // useAsyncEffect(async () => {
  //   const { enableCSP, policies } = await permissionService.getContentPolicies();
  //   setCSPSettings({ enableCSP, policies });
  // }, []);

  // const getIframeElement = (): HTMLElement & { contentWindow: Window & { console: Console } } => {
  //   return iframeParentDivRef?.current?.firstChild;
  // };
  // const getIframeWindow = () => {
  //   const iframe = getIframeElement();
  //   return iframe ? iframe.contentWindow : null;
  // };
  // const getIframeConsole = () => {
  //   const win = getIframeWindow();
  //   return win ? win.console : console;
  // };
  const buildContext = () => {
    return {
      // template,
      // runtime: ForgeRuntime.instance(),
      // parentWindow: window
      baseAssetPath: AppRuntime.instance().getAssetURL('/assets')
    };
  };

  const interceptConsoleError = (iframe) => {
    const win = iframe.contentWindow;
    const doc = win.document;
    ReflectionUtil.interceptCallback(
      win.console,
      ['log', 'error', 'trace', 'time', 'warn', 'info', 'debug'],
      ({ args, func: level }) => {
        args = args.map((msg) => {
          if (msg instanceof Error) {
            return `${msg.message} \n ${msg.stack}`;
          }
          return msg;
        });
        onConsoleMessage({ args, level });
      }
    );
  };

  const renderUIInFrame = async (iframe) => {
    const win = iframe.contentWindow;
    try {
      htmlService.instance().appenHtmlToIframe(iframe, {
        html,
        javascript,
        css,
        csps: cspSettings,
        context: buildContext(),
        bodyStyle
      });

      // await htmlService.instance().appendHtmlUnsafe(doc, { html, css, csps: cspSettings });
      await ConnectToIframe(iframe);
      // await htmlService.instance().appendScript(doc, javascript);
      // htmlService.instance().interceptListeners(doc);

      // htmlService.instance().dispatchDocEvent(doc, 'DOMContentLoaded');
      // onFrameContentRefresh && onFrameContentRefresh();
      //  htmlService.instance().appendScript(doc, javascript).appendStyle(doc, css); //this should be passed as html
    } catch (e) {
      console.error(e);
      onConsoleMessage({ args: [e], level: 'error' });
    } finally {
      // document.documentElement.style.height = 'fit-content';
      setTimeout(() => {
        onFrameContentRefresh && onFrameContentRefresh();
      }, 100); // imp as html rendering may take some time
    }
  };
  const renderTemplate = async () => {
    if (iframeParentDivRef.current) {
      RegisterPanpalHandlers({ template });
      // console.log('IframeRenderer debounced', { template });
      if (autoExecute || execute) {
        const iframe = htmlService
          .instance()
          .empty(iframeParentDivRef.current)
          .appendIframe(document, iframeParentDivRef.current, 'frame-renderer');
        interceptConsoleError(iframe);
        renderUIInFrame(iframe).finally(() => {
          onExecute();
        });
      }
    }
  };
  useEffect(() => {
    invokeWithDebounce(() => {
      renderTemplate();
    });
  }, [template, autoExecute, execute, cspSettings]);

  /**@type {any} */
  return (
    <div className="frame-wrapper" style={{ height: '100%' }}>
      <div ref={iframeParentDivRef} className="frame-main"></div>
    </div>
  );
}
