import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Col,
  Divider,
  Radio,
  Row,
  Space,
  Switch,
  Table,
  Input,
  Badge,
  Segmented,
  Pagination,
  Tooltip,
  Popover,
  Tag,
  Modal,
  Typography,
  Result,
  Skeleton,
  Spin,
  TourProps,
  Tour
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { TemplateService } from '../../../services/template.service';
import {
  PlusCircleFilled,
  DeleteFilled,
  EyeOutlined,
  ThunderboltOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  CheckOutlined,
  CopyOutlined,
  CloseOutlined,
  FormatPainterOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { TemplateRenderer } from './TemplateRenderer';
import { render } from 'react-dom';
import { DateUtil } from '../../../../system/utils/date.util';
import { NotificationService } from '../../../../system/services/ui/notification.service';
import { ReactIf } from '../../../../system/components/conditional/r.if';
const { Text, Link } = Typography;
const templateService = TemplateService.instance();
import { GtmService } from '../../../../system/services/stats/gtm.service';
import { AclService } from '../../../services/acl.service';
import { RowSelectionType } from 'antd/es/table/interface';
import MacroRenderer from '../../macro/renderer/macro.renderer';
import { TourService } from '../../../../system/services/ui/tour.service';

const aclService = AclService.instance();
const tourService = TourService.instance();

export default function TemplateList(props) {
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  /**@type {any[]} templates */
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [previewdId, setPreviewdId] = useState(null);
  const [openTour, setOpenTour] = useState(!tourService.isUserVisitedTour('template-list'));
  const tourRefs = {
    newButton: useRef(null),
    deleteButton: useRef(null),
    cloneButton: useRef(null),
    list: useRef(null),
    search: useRef(null)
  };
  const steps: TourProps['steps'] = [
    {
      title: 'New Template',
      description: 'Click here to create a new template.',
      target: () => tourRefs.newButton.current
    },
    {
      title: 'Delete Template',
      description: 'Select a template from the list and click here to delete it.',
      target: () => tourRefs.deleteButton.current
    },
    {
      title: 'Clone Template',
      description: 'Select a template from the list and click here to clone it.',
      target: () => tourRefs.cloneButton.current
    },
    {
      title: 'Search Template',
      description: 'Search templates by name.',
      target: () => tourRefs.search.current
    },
    {
      title: 'Template List',
      description: 'List of all templates.',
      target: () => tourRefs.list.current
    }
  ];
  useEffect(() => {
    GtmService.instance().pageview('/templates');
    (async () => {
      setLoading(true);
      try {
        const userTemplates = await templateService.getAllTemplates();
        setTemplates(userTemplates);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  // Queries
  const query = useQuery({
    queryKey: [templates, searchText, selectedType],
    queryFn: async () => {
      return templates.filter((r) => {
        let matched = true;
        if (selectedType === 'example') {
          matched = r.type === 'example';
        } else {
          matched = r.type !== 'example';
        }
        if (searchText.toLocaleLowerCase().length > 0) {
          matched =
            matched && r.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0;
        }
        return matched;
      });
    }
  });
  const getSelectedRows = () => {
    return selectedRowKeys.map((key) => {
      return templates.find((r) => r.id === key);
    });
  };
  const columns = [
    {
      title: '#',
      dataIndex: 'action',
      width: 40,
      render: (text, record, index) => (
        <Popover
          placement="topLeft"
          title={text}
          content={() => (
            <div style={{ width: '640px', height: '480px' }}>
              <ReactIf condition={!record.publishedAt}>
                <Result
                  status="404"
                  title="Template is not published"
                  subTitle="Only published templates can be used"></Result>
              </ReactIf>
              <ReactIf condition={record.publishedAt}>
                <TemplateRenderer id={record.id} config={{}}></TemplateRenderer>
              </ReactIf>
            </div>
          )}
          trigger="click">
          <Tooltip title="Preview">
            <a
              className={`preview-template-action ${previewdId === record.id ? 'active' : ''}`}
              onClick={(e) => {
                setPreviewdId(record.id);
                e.preventDefault();
              }}>
              <EyeOutlined />
            </a>
          </Tooltip>
        </Popover>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => {
        if (record.type === 'example') {
          return text;
        } else {
          return (
            <Tooltip title="Edit Template">
              <Button
                type="link"
                onClick={() => {
                  navigate(`/templates/${record.id}/edit`);
                }}>
                {text}
              </Button>
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render(text) {
        return (
          <div className="cell-description" style={{ overflow: 'hidden', height: '20px' }}>
            {text}
          </div>
        );
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      editable: true,
      render(text, record, index) {
        return (
          <span style={{ padding: '10px' }}>{text ? <CheckOutlined /> : <CloseOutlined />}</span>
        );
      }
    },
    {
      title: 'Tags',
      dataIndex: 'tags', //system or user defined
      render(text, record, index) {
        text = text || [];
        text = Array.isArray(text) ? text : text.split(',');
        return text.map((tag, i) => {
          return (
            <Tag color={templateService.getTagColor(tag)} key={i}>
              {tag}
            </Tag>
          );
        });
      }
    },
    // {
    //   title: 'Author',
    //   dataIndex: 'createdBy'
    // },
    // {
    //   title: 'Changed By',
    //   dataIndex: 'updatedBy'
    // },
    {
      title: 'Published At',
      dataIndex: 'publishedAt',
      render(text, record, index) {
        if (!record.publishedAt) return <Text type="warning">Unpublished</Text>;
        return DateUtil.formatDateString(record.publishedAt);
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(text, record, index) {
        return DateUtil.formatDateString(record.createdAt);
      }
    },
    {
      title: 'Changed At',
      dataIndex: 'updatedAt',
      render(text, record, index) {
        return DateUtil.formatDateString(record.updatedAt);
      }
    }
  ];
  const rowSelection = {
    type: 'radio' as RowSelectionType,
    selectedRowKeys,
    onChange(newSelectedRowKeys) {
      // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const deleteTemplate = async () => {
    const selectedTemplates = getSelectedRows();
    if (selectedTemplates.length === 0) return;
    if (selectedTemplates.some((t) => t.type === 'example')) return;
    if (selectedTemplates.length > 1) {
      NotificationService.instance().error({
        message: 'Please select only one template to delete'
      });
      return;
    }
    const selectedTemplate = selectedTemplates[0];
    const result = await modal.confirm({
      title: 'Delete Template',
      content: (
        <div>
          Are you sure to delete
          <Text type="danger" keyboard>
            {selectedTemplate.name}
          </Text>
          ?
        </div>
      ),
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await templateService.deleteTemplate(selectedTemplate);
          setSelectedRowKeys([]);
          setTemplates(templates.filter((t) => t.id !== selectedTemplate.id));
          NotificationService.instance().success({ message: 'Template deleted successfully' });
        } catch (e) {
          console.error(e);
          NotificationService.instance().error({ message: 'Failed to delete template' });
        }
      }
    });
  };
  const configureTemplate = (templateId: string) => {
    navigate(`/templates/${templateId}/configure`);
  };
  // rowSelection object indicates the need for row selection
  return (
    <div className="list template-list">
      <div className="modal-context-holder">{contextHolder}</div>
      <div className="action-bar" style={{ padding: '10px' }}>
        <Row>
          <Col span={15}>
            <Spin spinning={aclService.isLoading()} indicator={<LoadingOutlined />}>
              <ReactIf condition={aclService.isInitialized() && aclService.canEdit()}>
                <Space>
                  <Button
                    ref={tourRefs.newButton}
                    type="primary"
                    icon={<PlusCircleFilled />}
                    onClick={() => {
                      navigate('/templates/new/edit');
                    }}>
                    New
                  </Button>

                  <Button
                    ref={tourRefs.deleteButton}
                    icon={<DeleteFilled />}
                    danger
                    disabled={
                      selectedType === 'example' ||
                      selectedRowKeys.length === 0 ||
                      selectedRowKeys.length > 1
                    }
                    onClick={deleteTemplate}>
                    Delete
                  </Button>
                  <Button
                    ref={tourRefs.cloneButton}
                    disabled={selectedRowKeys.length === 0 || selectedRowKeys.length > 1}
                    icon={<CopyOutlined />}
                    onClick={() => navigate(`/templates/new/edit?sourceId=${selectedRowKeys[0]}`)}>
                    Clone
                  </Button>
                  {/* <Button
                    icon={<FormatPainterOutlined />}
                    disabled={selectedRowKeys.length === 0 || selectedRowKeys.length > 1}
                    onClick={() => {
                      configureTemplate(selectedRowKeys[0]);
                    }}>
                    Configure
                  </Button> */}
                </Space>
              </ReactIf>
            </Spin>
          </Col>
          <Col span={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Badge ref={tourRefs.search} count={searchText.trim() !== '' ? 1 : 0} status="warning">
              <Space>
                {/* <Radio.Group
                  value={selectedType}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}>
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value="example">Examples</Radio.Button>
                </Radio.Group> */}
                <ReactIf condition={selectedRowKeys.length === 1}>
                  <Tag color="blue" style={{ padding: '5px' }}>
                    {selectedRowKeys.length === 1 && getSelectedRows()[0].name}
                  </Tag>
                </ReactIf>
                <Input.Search
                  placeholder="search"
                  onSearch={(value) => {
                    setSearchText(value);
                  }}
                  style={{
                    width: 200
                  }}
                />
              </Space>
            </Badge>
          </Col>
        </Row>
      </div>

      <div className="grid" ref={tourRefs.list}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setSelectedRowKeys([record.id]);
              }, // click row
              onDoubleClick: (event) => {
                navigate(`/templates/${record.id}/edit`);
              }, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {} // mouse leave row
            };
          }}
          loading={{ spinning: loading, tip: 'Loading...', indicator: <LoadingOutlined /> }}
          rowKey="id"
          sticky={true}
          size="middle"
          rowSelection={rowSelection}
          columns={columns}
          // @ts-ignore
          dataSource={query.data}
          pagination={{
            defaultPageSize: 15,
            showQuickJumper: true,
            showTotal: (total) => `Total ${total} of ${query.data?.length} items`,
            showSizeChanger: true
          }}
        />
      </div>
      <Tour
        open={openTour}
        onClose={() => {
          setOpenTour(false);
          tourService.endTour('template-list');
        }}
        steps={steps}
      />
    </div>
  );
}
