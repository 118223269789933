import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import IframeRenderer from '../../editor/IframeRenderer';
import htmlService from '../../../services/html.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TemplateRenderer } from '../../admin/template/TemplateRenderer';
import ErrorPage from '../../../../../error-page';
import { Button, FloatButton, Result, Skeleton, Tooltip } from 'antd';
import { AppRuntime } from '../../../../vendor/services/app.runtime';
import { TemplateService } from '../../../services/template.service';
import { ITemplateEntity } from '../../../model/itemplate';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { ITemplateConfig } from '../../../model/itemplate.config';
import {
  UserJourneyEvent,
  UserJourneyService
} from '../../../../system/services/ui/user.journey.service';
import { TemplateConfiguration } from '../macro-config/TemplateConfiguration';

const runtime = AppRuntime.instance();
const templateService = TemplateService.instance();
interface InstanceConfig {
  templateConfig?: ITemplateConfig;
  template?: ITemplateEntity;
  instanceId?: string;
}
interface MacroRendererProps {
  templateConfig: ITemplateConfig & { openConfigView?: boolean };
  onApplyConfig: (any) => any;
  openConfigView?: boolean;
  hideConfigButton?: boolean;
}
export const MacroRenderer = forwardRef(
  (
    { templateConfig, onApplyConfig, openConfigView, hideConfigButton }: MacroRendererProps,
    ref
  ) => {
    const [loading, setLoading] = useState(true);
    const [template, setTemplate] = useState<ITemplateEntity>(null);
    const [showConfig, setShowConfig] = useState(openConfigView);
    const navigate = useNavigate();
    const templateConfigRef = useRef<any>();

    useImperativeHandle(ref, () => ({
      async getTemplateConfig(): Promise<ITemplateConfig> {
        return templateConfigRef?.current.getTemplateConfig();
      }
    }));
    useAsyncEffect(async () => {
      setLoading(true);
      try {
        let template = null;
        if (templateConfig.templateId) {
          template = await templateService.getTemplateById(templateConfig.templateId);
          template.variables = (template.variables || []).map((v) => {
            if (typeof templateConfig.variables[v.name] !== 'undefined') {
              v.value = templateConfig.variables[v.name];
            }
            return v;
          });
          setTemplate(template);
          setShowConfig(false);
        }
        UserJourneyService.instance().logEvent(UserJourneyEvent.MACRO_RENDERED, {
          templateId: templateConfig.templateId
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }, [templateConfig]);

    if (showConfig || openConfigView) {
      return (
        <div className="macro-renderer">
          <TemplateConfiguration
            templateConfig={templateConfig}
            onApplyConfig={async (config) => {
              await onApplyConfig(config);
              setShowConfig(false);
            }}
            ref={templateConfigRef}
          />
        </div>
      );
    }
    if (!templateConfig.templateId) {
      return (
        <div className="macro-renderer">
          <Result
            title="HTML Template not configured."
            subTitle={
              <div style={{ display: hideConfigButton ? 'none' : 'block' }}>
                Click &nbsp;
                <a
                  type="link"
                  onClick={() => {
                    setShowConfig(true);
                  }}>
                  here
                </a>
                &nbsp; to configure
              </div>
            }
          />
          <Tooltip title="Configure">
            <FloatButton
              className="configure-template-btn"
              style={{
                display: templateConfig.enableConfigAction ? 'block' : 'none'
              }}
              onClick={() => {
                setShowConfig(true);
              }}
              icon={<i className="fa fa-cog"></i>}
            />
          </Tooltip>
        </div>
      );
    }
    return (
      <div className="macro-renderer">
        <TemplateRenderer
          template={template}
          config={templateConfig}
          onConfigButtonClick={() => setShowConfig(true)}></TemplateRenderer>
      </div>
    );
  }
);

MacroRenderer.displayName = 'MacroRenderer';
