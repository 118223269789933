import { ResolverApiRoutes } from '../../../api.routes';
import { QueryMethod } from './forge.model.resolver.api';
import { ForgeResolverApi } from './forge.resolver.api';

const forgeResolverApi = ForgeResolverApi.instance();

export class ForgeStorageQuery<T> {
  cache = {};
  constructor(protected tableName: string) {
    if (!tableName) {
      throw new Error('tableName is required');
    }
  }
  /**
   *
   * @param {string} id
   * @param {Record<string,any>} options
   * @returns {Promise<any>}
   */
  async findById(
    id: string,
    options: Record<string, any> = { references: [], cached: false }
  ): Promise<T> {
    if (!id) {
      throw new Error('id is required');
    }
    if (options.cached && this.cache[id]) {
      return this.cache[id];
    }
    const reponse = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
      queryMethod: QueryMethod.FIND_BY_ID,
      tableName: this.tableName,
      query: { id: id, ...options }
    });
    const result = reponse.contents;
    if (options.cached) {
      this.cache[id] = result;
    }
    return result;
  }

  /**
   *
   * @param {Record<string,any>} query
   * @param {Record<string,any>} options
   * @returns {Promise<any>}
   * */
  async findAll(query: Record<string, any>, options = { references: [] }): Promise<T[]> {
    const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
      queryMethod: QueryMethod.FIND_ALL,
      tableName: this.tableName,
      query: { ...query, ...options }
    });
    return response.contents;
  }
  async findByIds(ids: string[], options = { references: [] }): Promise<T[]> {
    const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
      queryMethod: QueryMethod.FIND_BY_ID,
      tableName: this.tableName,
      query: { ids, ...options }
    });
    return response.contents;
  }
  async deleteByIds(ids: string[], options = { references: [] }): Promise<T[]> {
    const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
      queryMethod: QueryMethod.DELETE,
      tableName: this.tableName,
      query: { ids, ...options }
    });
    return response.contents;
  }
  async create(data: any, options = { references: [] }): Promise<T> {
    if (!data) {
      throw new Error('data is required');
    }
    const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
      queryMethod: QueryMethod.CREATE,
      tableName: this.tableName,
      query: { ...options },
      data
    });
    return response.contents;
  }
  async update(data: any, options = { references: [] }): Promise<T> {
    if (!data.id) {
      throw new Error('data.id is required');
    }
    const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
      queryMethod: QueryMethod.UPDATE,
      tableName: this.tableName,
      query: {
        id: data.id,
        ...options
      },
      data
    });
    return response.contents;
  }
  async upSert(data: any, options = { references: [] }): Promise<T> {
    if (data.id) {
      return await this.update(data, options);
    }
    return await this.create(data, options);
  }
}
