import { PanpalEventRegistry } from '../../system/services/messaging/panpal.event.handler';
import { PanpalParentService } from '../../system/services/messaging/panpal.parent.service';
import { IssueEventHandler } from './item.event.handler';
import { TemplateConfigEventHandler } from './template.config.event.handler';
import { VariableEventHandler } from './variable.event.handler';

const panpalRegistry = PanpalEventRegistry.instance();

export const RegisterPanpalHandlers = (context: any) => {
  panpalRegistry
    .cleanup()
    .register(
      new IssueEventHandler(context),
      new VariableEventHandler(context),
      new TemplateConfigEventHandler(context)
    );
  // last statement
};
export const ConnectToIframe = async (iframeDOM: HTMLElement) => {
  return await PanpalParentService.instance().connect(iframeDOM);
};
