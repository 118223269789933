export default {
  name: 'Image Slider',
  description: `The Image Slider Html Template is a pre-built template that allows users to easily create and display an image slideshow .`,
  category: 'Misc / Slider',
  tags: ['image', 'slider'],
  active: true,
  icon: 'fa-solid fa-vimeo',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<!-- HTML -->
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <title>Bootstrap Example</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
  </head>
  <body>
      
  <div class="container">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ol class="carousel-indicators">
        <!--<li data-target="#myCarousel" data-slide-to="0" class="active"></li>
        <li data-target="#myCarousel" data-slide-to="1"></li>
        <li data-target="#myCarousel" data-slide-to="2"></li>-->
      </ol>
  
      <!-- Wrapper for slides -->
      <div class="carousel-inner">
        <div class="item active">
          <img src="#" alt="Los Angeles" style="width:100%;">
        </div>
  
      </div>
  
      <!-- Left and right controls -->
      <a class="left carousel-control" href="#myCarousel" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  
  </body>
  </html>
   `,
  script: `/** JavaScript */
  $template.ready(async () => {
      const variables = $template.useVariables(); // get variables service
      const images = await variables.getValue('images');
      const $jQTemplate = $('<div/>').addClass('item');
      const $wrapper = $('.carousel-inner').empty();
      for(const img of images.split(',')){
          $jQTemplate.clone().append('<img src="'+img.trim()+'" style="width:100%;">').appendTo($wrapper);
      }
      $wrapper.children().first().addClass('active')
    });`,
  css: ``,
  variables: [
    {
      label: 'Images',
      name: 'images',
      type: 'multilineString',
      defaultFieldValue:
        'https://tinyurl.com/htmltemplateslider,https://tinyurl.com/htmltemplateslider2,https://tinyurl.com/htmltemplateslider3',
      mandatory: true,
      description: 'Image urls separated by comma(,)'
    }
  ]
};
