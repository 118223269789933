import { useEffect, useRef, useState } from 'react';
import { ITemplateConfig } from '../../../model/itemplate.config';
import { MacroRenderer } from './macro.renderer';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { Modal, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import { ConnectRuntime } from '../../../../connect/services/connect.runtime';

const connectRuntime = ConnectRuntime.instance();

export function ConnectMacroRenderer() {
  const [templateConfig, setTemplateConfig] = useState<ITemplateConfig>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();
  const { editor = 'false' } = useParams();
  const macroRendereRef = useRef<any>();

  connectRuntime.configCallback = async () => {
    return macroRendereRef.current.getTemplateConfig();
  };

  useAsyncEffect(async () => {
    setLoading(true);
    connectRuntime.initMacroConfig();
    try {
      const config = await connectRuntime.getMacroConfig();
      setTemplateConfig(config);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  return (
    <Skeleton loading={loading} active>
      <MacroRenderer
        ref={macroRendereRef}
        onApplyConfig={async (config) => {
          setTemplateConfig(config);
          setLoading(true);
          try {
            await connectRuntime.saveMacroConfig(config);
          } catch (e) {
            console.error(e);
          }
          setLoading(false);
        }}
        templateConfig={templateConfig}
        openConfigView={connectRuntime.getParam('editor') === 'true'}
      />
    </Skeleton>
  );
}
