import { env } from '../../../env';
import { ForgeContext } from '../../forge/services/forge.runtime';
import { BeanManager } from '../../system/services/system/bean.manager';
import { KeyValStore } from '../../system/store/key.val.store';
import { ReflectionUtil } from '../../system/utils/reflection.util';
const AP: any = window['AP'];

const params = new URLSearchParams(window.location.search);
const hashPart = window.location.hash.split('?');
const hashSearchPamrams = new URLSearchParams(hashPart.length > 1 ? hashPart[1] : '');
const allParams = new URLSearchParams();
params.forEach((value, key) => allParams.set(key, value));
hashSearchPamrams.forEach((value, key) => allParams.set(key, value));
export interface AppConfig {
  webTriggerUrl: string;
  siteName: string;
}
export const ConnectRuntime = BeanManager.register(
  class ConnectRuntime {
    public configCallback = async () => {};
    async getContext(): Promise<ForgeContext> {
      return new Promise((resolve, reject) => {
        AP.context.getContext(function (context) {
          resolve({
            ...context,
            extension: context.extension || {
              type: 'connectModules:confluence:dynamicContentMacros'
            }
          });
        });
      });
    }
    public webTriggerUrl: string;
    async getCloudId() {
      const siteURL = allParams.get('xdm_e');
      if (siteURL) {
        const cloudIdResponse = await fetch(`${siteURL}/_edge/tenant_info`);
        const cloudInfo = await cloudIdResponse.json();
        return cloudInfo.cloudId;
      }
      return env.APP_ID;
    }
    async getAppConfig(): Promise<AppConfig> {
      const cloudId = this.getCloudId();
      const configKey = `${cloudId}:config`;
      return await KeyValStore.instance().get(configKey);
    }

    async getWebtriggerUrl() {
      if (!this.webTriggerUrl) {
        try {
          this.webTriggerUrl = (await this.getAppProperty('webtrigger-url')) as string;
        } catch (e) {
          console.warn('error while getting webtrigger url ,fetching from source', e);
        }
        if (!this.webTriggerUrl) {
          const config = await this.getAppConfig();
          this.webTriggerUrl = config.webTriggerUrl;
          await this.setAppProperty('webtrigger-url', this.webTriggerUrl);
        }
      }
      return this.webTriggerUrl;
    }
    async isRunningInConnect() {
      return (await this.getParam('connect')) === 'true';
    }
    async getAppProperty(key: string) {
      return new Promise((resolve, reject) => {
        AP.request({
          url: `/rest/atlassian-connect/1/addons/${env.APP_ID}/properties/${key}?jsonValue=true`,
          success: function (response) {
            // Convert the string response to JSON
            response = JSON.parse(response);
            resolve(response.value);
          },
          error: function (response) {
            console.error('error while getting app prop', key, response);
            reject(response);
          },
          contentType: 'application/json'
        });
      });
    }
    async setAppProperty(key: string, value: any) {
      return new Promise((resolve, reject) => {
        AP.request({
          url: `/rest/atlassian-connect/1/addons/${env.APP_ID}/properties/${key}?jsonValue=true`,
          type: 'PUT',
          data: JSON.stringify(value),
          contentType: 'application/json',
          success: function (response) {
            resolve(response);
          },
          error: function (response) {
            console.error('error while setting app prop', key, response);
            reject(response);
          }
        });
      });
    }
    initMacroConfig() {
      // @ts-ignore
      const xthis = this;
      AP.require(['confluence', 'dialog'], function (confluence, dialog) {
        dialog.getButton('submit').bind(function () {
          xthis.configCallback().then((config) => {
            if (!config?.valid) return;
            confluence.saveMacro({}, JSON.stringify(config));
            confluence.closeMacroEditor();
          });
          return false;
        });
      });
    }
    async saveMacroConfig(config) {
      return new Promise((resolve, reject) => {
        AP.require(['confluence', 'dialog'], function (confluence, dialog) {
          confluence.saveMacro({}, JSON.stringify(config));
          // confluence.closeMacroEditor();
          // resolve(config);
        });
      });
    }
    async getMacroConfig() {
      return new Promise((resolve, reject) => {
        AP.require(['confluence'], function (confluence) {
          confluence.getMacroBody(function (data) {
            resolve(ReflectionUtil.safeParse(data, data));
          });
        });
      });
    }
    getParam(name: string): any {
      return allParams.get(name);
    }
    isInEditorWindow() {
      return this.getParam('editor') === 'true';
    }
    getOutputType(): 'display' | 'preview' {
      return this.getParam('outputType');
    }
  }
);
