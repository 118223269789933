import { ResolverApiRoutes } from '../../../api.routes';
import { env } from '../../../env';
import { QueryMethod } from '../../forge/api/forge.model.resolver.api';
import { ForgeResolverApi } from '../../forge/api/forge.resolver.api';
import { ForgeApiError } from '../../forge/api/forge.resolver.api.response';
import { BeanManager } from '../../system/services/system/bean.manager';
import { ReflectionUtil } from '../../system/utils/reflection.util';
import { AppRuntime } from '../../vendor/services/app.runtime';
import { DEFAULT_TEMPLATE_CONFIG, ITemplateConfig } from '../model/itemplate.config';
import { TableNames } from '../model/table.names';

export const PROJECT_CONFIG_KEY_PREFIX = 'com.appknitters.html.template.config';
const forgeResolverApi = ForgeResolverApi.instance();

const runtime = AppRuntime.instance();
export const TemplateConfigService = BeanManager.register(
  class TemplateConfigService {
    async newTemplateConfig(props: Partial<ITemplateConfig>): Promise<ITemplateConfig> {
      const context = await runtime.getContext();
      return {
        ...ReflectionUtil.clone(DEFAULT_TEMPLATE_CONFIG),
        ...props,
        enableConfigAction: false // context.extension.type === 'jira:issuePanel'
      };
    }
    async getAllTemplateConfigs(): Promise<ITemplateConfig[]> {
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.FIND_ALL,
        tableName: TableNames.HtmlTemplateConfig
      });
      return response.contents;
    }
    async getIssueTemplateConfig(): Promise<ITemplateConfig[]> {
      const context = await runtime.getContext();
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.getIssueTemplateConfig, {
        issueId: context.extension.issue.key,
        projectId: context.extension.project.key,
        issueType: context.extension.issue.type,
        uniqueIssueId: context.extension.issue.id
      });
      const configs: ITemplateConfig[] = response.contents;
      return configs;
    }
    async saveTemplateConfig(templateConfig: ITemplateConfig): Promise<ITemplateConfig> {
      try {
        const result = await forgeResolverApi.invoke(ResolverApiRoutes.saveIssueTemplateConfig, {
          templateConfig
        });
        return result.contents;
      } catch (e) {
        if (e instanceof ForgeApiError) {
          throw e;
        }
        console.error(e);
      }
    }
    async saveIssueTemplateConfig(templateConfig: ITemplateConfig): Promise<ITemplateConfig> {
      const context = await runtime.getContext();
      return await this.saveTemplateConfig({
        ...templateConfig,
        issueId: context.extension.issue.key,
        projectId: context.extension.project.key,
        extensionType: context.extension.type
      });
    }

    async deleteTemplateConfig(templateConfig: ITemplateConfig): Promise<any> {
      const result = await forgeResolverApi.invoke(ResolverApiRoutes.deleteIssueTemplateConfig, {
        templateConfig
      });
      return result.contents;
    }
    async getWidgetTemplateConfig(widgetId: string): Promise<ITemplateConfig> {
      if (await runtime.isRunningInForge()) {
        const context = await runtime.getContext();
        return context.extension.gadgetConfiguration?.templateConfig;
      }
      const serializedConfig = localStorage.getItem(
        `${env.APP_ID}-dashboard-widget-config-${widgetId}`
      );
      return serializedConfig ? JSON.parse(serializedConfig) : await this.newTemplateConfig({});
    }
    async saveWidgetTemplateConfig(widgetId: string, templateConfig: ITemplateConfig) {
      if (await runtime.isRunningInForge()) {
        const { view } = await import('@forge/bridge');
        await view.submit({ templateConfig });
      }
      return localStorage.setItem(
        `${env.APP_ID}-dashboard-widget-config-${widgetId}`,
        JSON.stringify(templateConfig)
      );
    }
  }
);
