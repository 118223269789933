import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import { createBrowserRouter, createHashRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './error-page';
import { MacroRenderer } from './modules/advance-html/components/macro/renderer/macro.renderer';
import { QueryClient } from '@tanstack/react-query';
import { TemplateFormView } from './modules/advance-html/components/admin/template/TemplateFormView';
import 'allotment/dist/style.css';
import { ForgeNavigator } from './forge.navigator';
import MacroConfigPopup from './modules/advance-html/components/macro/macro-config/macro.config.popup';
import TemplateList from './modules/advance-html/components/admin/template/TemplateList';
import { TemplateRenderer } from './modules/advance-html/components/admin/template/TemplateRenderer';
import AdminComponent from './modules/advance-html/components/admin/Admin';
import { TemplateConfiguration } from './modules/advance-html/components/macro/macro-config/TemplateConfiguration';
import { IssueViewMacroRenderer } from './modules/advance-html/components/macro/renderer/IssueViewMacroRenderer';
import { DashboardWidgetMacroRenderer } from './modules/advance-html/components/macro/renderer/DashboardWidgetMarcoRenderer';
import { ConnectMacroRenderer } from './modules/advance-html/components/macro/renderer/ConnectMacroRenderer';

const defaultData = {
  borderRadius: 3,
  colorPrimary: '#1677ff'
};
const queryClient = new QueryClient();

const router = createHashRouter([
  {
    path: '/',
    element: <ForgeNavigator />,
    errorElement: <ErrorPage />
  },
  {
    path: '/admin-page',
    element: <AdminComponent />,
    errorElement: <ErrorPage />
  },
  {
    path: '/templates',
    element: <TemplateList />,
    errorElement: <ErrorPage />
  },
  {
    path: '/templates/:id/edit',
    element: <TemplateFormView />,
    errorElement: <ErrorPage />
  },
  {
    path: '/html-template-macro-editor',
    element: <ConnectMacroRenderer />,
    errorElement: <ErrorPage />
  },
  {
    path: '/macro/render/:type',
    element: <IssueViewMacroRenderer />,
    errorElement: <ErrorPage />
  },

  {
    path: '/macro/render-dashboard-widget',
    element: <DashboardWidgetMacroRenderer />,
    errorElement: <ErrorPage />
  },
  {
    path: '/mock-macro/render/:id',
    element: <TemplateRenderer />,
    errorElement: <ErrorPage />
  },
  {
    path: '/macro/config',
    element: <MacroConfigPopup />,
    errorElement: <ErrorPage />
  },
  {
    path: '/error-page',
    element: <ErrorPage />,
    errorElement: <ErrorPage />
  },
  {
    path: '*',
    element: <ForgeNavigator />,
    errorElement: <ErrorPage />
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <App>
    <RouterProvider router={router} />
  </App>
);
