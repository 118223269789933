export default {
  name: 'Google Map',
  description: `The Google Maps template example includes the necessary HTML, CSS, and JavaScript code to display a Google Map . It allows users to easily customize the map by specifying the location, zoom level, and map type. This example creates a map that's centered on Sydney, New South Wales, Australia.`,
  category: 'Google / Map',
  active: true,
  tags: ['map', 'google'],
  icon: 'fa-solid fa-map-location-dot',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<html>

  <head>
      <title>Simple Map</title>
      <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
  </head>
  
  <body>
      <div id="map"></div>
      <!-- 
        The defer attribute causes the callback to execute after the full HTML
        document has been parsed. For non-blocking uses, avoiding race conditions,
        and consistent behavior across browsers, consider loading using Promises
        with https://www.npmjs.com/package/@googlemaps/js-api-loader.
        -->
      <script>
          window.initMap = ()=>{
              setTimeout(()=>{onMapLoad()},500);
          }
      </script>
      <script src="https://maps.googleapis.com/maps/api/js?&v=weekly&callback=initMap"></script>
  
  </body>
  
  </html>`,
  script: `window.onMapLoad = async function() {
    const variables = $template.useVariables(); // get variables service
    const lat = parseInt(await variables.getValue('lat'));
    const lng = parseInt(await variables.getValue('lng'));
    const zoom = parseInt(await variables.getValue('zoom'));
    let map;
    // @ts-ignore
    map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: lat, lng: lng },
        zoom: zoom,
    });
};`,
  css: ` 
  /* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 */
#map {
  height: 100%;
}

/* 
 * Optional: Makes the sample page fill the window. 
 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}`,
  variables: [
    {
      name: 'lat',
      type: 'number',
      defaultFieldValue: -34.397,
      description: 'Latitude'
    },
    {
      name: 'lng',
      type: 'number',
      defaultFieldValue: 150.644,
      description: 'Longitude'
    },
    {
      name: 'zoom',
      type: 'number',
      defaultFieldValue: 8,
      description: 'Zoom'
    }
  ]
};
