import { env } from '../../../env';
import { ConnectRuntime } from '../../connect/services/connect.runtime';
import { BeanManager } from '../../system/services/system/bean.manager';
import { MockContextData } from '../../vendor/mock/app.mock.context.data';

export interface ForgeContext {
  cloudId?: string;
  localId?: string;
  environmentId?: string;
  environmentType?: string;
  moduleKey?: string;
  siteUrl?: string;
  extension?: Record<string, any> & {
    type?:
      | 'confluence:globalSettings'
      | 'jira:issuePanel'
      | 'jira:dashboardGadget'
      | 'jira:globalPage'
      | 'confluence:macro'
      | 'confluence:globalPage'
      | 'confluence:spacePage'
      | 'confluence:spaceTools'
      | 'confluence:spaceSidebar'
      | 'confluence:spaceBlueprint'
      | 'confluence:spaceAdmin'
      | 'confluence:spaceContent'
      | 'confluence:spaceAttachments'
      | 'confluence:spaceExport'
      | 'confluence:spaceImport'
      | 'confluence:spacePermissions'
      | 'confluence:spaceSettings'
      | 'confluence:spaceSide'
      | 'connectModules:confluence:dynamicContentMacros';
  };
  accountId?: string;
  timezone?: string;
  locale?: string;
  theme?: any;
}
export const ForgeRuntime = BeanManager.register(
  class ForgeRuntime {
    url = new URL(window.location.href);
    context: ForgeContext;
    constructor() {}
    getQueryParam(name: string) {
      return this.url.searchParams.get(name);
    }
    async getRenderingMode(): Promise<string> {
      const isRunningInForge = await this.isRunningInForge();
      if (!isRunningInForge) {
        return 'local';
      }
      const context = await this.getContext();
      const extensionType = context.extension.type;
      if (!extensionType) {
        return 'admin';
      }
      return extensionType;
    }
    async getModalRoute(): Promise<string | null> {
      const isRunningInModal = await this.isRenderingInModal();
      if (!isRunningInModal) {
        return null;
      }
      const context = await this.getContext();
      return context.extension?.modal.route;
    }
    async isRenderingInModal(): Promise<boolean> {
      const isRunningInForge = await this.isRunningInForge();
      if (!isRunningInForge) {
        return false;
      }
      const context = await this.getContext();
      return typeof context.extension.modal !== 'undefined';
    }
    async isRenderringAsMacro(): Promise<boolean> {
      const isRunningInForge = await this.isRunningInForge();
      if (!isRunningInForge) {
        return false;
      }
      const context = await this.getContext();
      return context.extension.type === 'macro';
    }

    /**
     * {
          "cloudId": "84fc4888-bc55-41a9-a381-e5b8f99dcf30",
          "localId": "ari-cloud-ecosystem--extension-4861bde0-0bee-449f-a4aa-9ead87a3ee5b-dd78e327-cdca-4de1-ae25-815a76120587-static-forge-html-template-global-page",
          "environmentId": "dd78e327-cdca-4de1-ae25-815a76120587",
          "environmentType": "DEVELOPMENT",
          "moduleKey": "forge-html-template-global-page",
          "siteUrl": "https://reboot-dev.atlassian.net",
          "extension": {
              "type": "confluence:globalPage"
          },
          "accountId": "5d0e2cf2b125a40c54fef96b",
          "timezone": "Asia/Calcutta",
          "locale": "en-US",
          "theme": null
      }
     */
    async getContext(): Promise<ForgeContext> {
      if (await ConnectRuntime.instance().isRunningInConnect()) {
        return await ConnectRuntime.instance().getContext();
      }
      if (!this.context) {
        if (this.inIframe()) {
          const { view } = await import('@forge/bridge');
          this.context = await view.getContext();
        } else {
          this.context = MockContextData[env.MOCK_VIEW_TYPE]; // local context here
        }
      }
      return this.context;
    }
    async isRunningInForge() {
      return this.inIframe() && !!(await this.getContext());
    }
    async isRunningInJira() {
      if (this.inIframe()) {
        const context = await this.getContext();
        return !context.extension?.type?.startsWith('confluence:');
      }
      return false;
    }
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
    getAssetURL(assetPath: string) {
      const urlWithoutHash = this.url.href
        .replace(this.url.hash, '')
        .replace(this.url.search, '')
        .replace('?', '');
      let assetURL = `${urlWithoutHash}${assetPath}`;
      if (urlWithoutHash.endsWith('/') && assetPath.startsWith('/')) {
        assetURL = `${urlWithoutHash}${assetPath.substring(1)}`;
      }
      return assetURL.replace('//asset', '/asset');
    }
    showFullBody() {
      document.body.style.overflowY = 'visible';
    }

    setBodyHeight(height: any, setMaxHeight = true) {
      document.body.style.height = height + 'px';
      if (setMaxHeight) {
        document.body.style.maxHeight = height + 50 + 'px';
      }
    }
    adjustHeightBasedOnRootDiv(expandBy = 100, timeout = 500) {
      setTimeout(() => {
        const rootDivHeight = document.getElementById('root').clientHeight + expandBy;
        if (rootDivHeight < 500) {
          return this.adjustHeightBasedOnRootDiv(1000);
        }
        this.setBodyHeight(rootDivHeight);
      }, timeout);
    }
    setPageHeight(height: any) {
      // const runtime = ForgeRuntime.instance();
      // if (runtime.inIframe()) {
      //   const view = this.getView();
      //   view.setFrameHeight(height);
      // } else {
      if (height) {
        if (height.toLowerCase() === 'auto') {
          return;
        }
        if (!isNaN(height)) {
          height = height + 'px';
        }
        document.getElementById('root').style.height = height;
        document.body.style.height = height;
        document.body.style.maxHeight = height;
        document.documentElement.style.height = height;
        setTimeout(() => {
          document.body.style.overflow = 'hidden'; // to fix the scroll issue
        }, 500);
      }
      //}
    }
    async getIssueTypes() {
      const { requestJira } = await import('@forge/bridge');
      const context = await this.getContext();
      const projectId = context.extension?.project?.key;
      const response = await requestJira('/rest/api/3/issuetype');
      console.log('response', response);
      return response.json();
    }
  }
);
