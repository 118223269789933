import { useEffect, useState } from 'react';
import { ITemplateConfig } from '../../../model/itemplate.config';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { TemplateConfigService } from '../../../services/template.config.service';
import { Button, Modal, Skeleton, Space, Tabs, Tooltip, notification } from 'antd';
import { MacroRenderer } from './macro.renderer';
import { Outlet } from 'react-router-dom';
import { InlineEdit } from '../../../../system/components/input/inline.edit';
import { ReflectionUtil } from '../../../../system/utils/reflection.util';
import { ForgeApiError } from '../../../../forge/api/forge.resolver.api.response';
import { NotificationService } from '../../../../system/services/ui/notification.service';
import { EditOutlined, CheckCircleOutlined } from '@ant-design/icons';

const templateConfigService = TemplateConfigService.instance();

type ConfigType = ITemplateConfig & { openConfigView?: boolean };
const notificationService = NotificationService.instance();
export function IssueViewMacroRenderer() {
  const [templateConfigs, setTemplateConfigs] = useState<ConfigType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const [activeTabId, setActiveTabId] = useState<string>('');

  const [modal, contextHolder] = Modal.useModal();

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const issueConfigs = await templateConfigService.getIssueTemplateConfig();
      if (issueConfigs.length === 0) {
        issueConfigs.push(
          await templateConfigService.newTemplateConfig({
            id: ReflectionUtil.uuid(),
            title: 'New Template'
          })
        );
      }
      setTemplateConfigs(issueConfigs);
      setActiveTabId(issueConfigs[0].id);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);
  const saveConfig = async (config: ITemplateConfig, index?: number) => {
    try {
      await templateConfigService.saveIssueTemplateConfig(config);
      if (!index) {
        index = templateConfigs.findIndex((c) => c.id === config.id);
      }
      templateConfigs[index] = config;
      setTemplateConfigs([...templateConfigs]);
    } catch (e) {
      if (e instanceof ForgeApiError) {
        const response = e.response;
        if (response.status === 400) {
          if (response.message.toLowerCase().indexOf('invalid jql') >= 0) {
            notificationService.error({
              message: 'Invalid JQL',
              description: 'Please check the JQL syntax.'
            });
          }
        }
      }
      throw e;
    }
  };
  useEffect(() => {
    const newItems = templateConfigs.map((config, index) => {
      return {
        key: config.id,
        label: (
          <div>
            <span>{config.title}</span>
            <Tooltip title="edit">
              <Button
                size="small"
                type="link"
                onClick={() => {
                  setTemplateConfigs(
                    templateConfigs.map((c, i) => {
                      if (i === index) {
                        return { ...c, openConfigView: true };
                      }
                      return c;
                    })
                  );
                }}
                icon={<EditOutlined />}></Button>
            </Tooltip>
          </div>
        ),
        children: (
          <MacroRenderer
            templateConfig={config}
            onApplyConfig={async (config) => {
              await saveConfig({ ...config }, index);
              templateConfigs[index] = { ...config, openConfigView: false };
              setTemplateConfigs([...templateConfigs]);
            }}
            openConfigView={config.openConfigView}
          />
        )
      };
    });
    setItems(newItems);
  }, [templateConfigs]);
  const onEdit = async (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      const newConfig = await templateConfigService.newTemplateConfig({
        id: ReflectionUtil.uuid(),
        title: 'New Template'
      });
      templateConfigs.push(newConfig);
      setTemplateConfigs([...templateConfigs]);
      setActiveTabId(newConfig.id);
    }
    if (action === 'remove') {
      modal.confirm({
        title: 'Are you sure to delete this configuration?',
        content: 'This action cannot be undone.',
        onOk: async () => {
          const config = templateConfigs.find((config) => config.id === targetKey);
          const newConfigs = templateConfigs.filter((config) => config.id !== targetKey);
          await templateConfigService.deleteTemplateConfig(config);
          setTemplateConfigs([...newConfigs]);
          if (templateConfigs.length > 0) {
            setActiveTabId(templateConfigs[0].id);
          }
        }
      });
    }
  };

  return (
    <Skeleton loading={loading} active>
      {contextHolder}
      <Tabs
        style={{ height: '100%' }}
        activeKey={activeTabId}
        onChange={(key: string) => setActiveTabId(key)}
        type="editable-card"
        onEdit={onEdit}
        items={items}></Tabs>
    </Skeleton>
  );
}
