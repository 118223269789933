import { ResolverApiRoutes } from '../../../api.routes';
import { BeanManager } from '../../system/services/system/bean.manager';
import { ForgeResolverApi } from './forge.resolver.api';

const forgeResolverApi = ForgeResolverApi.instance();
export enum QueryMethod {
  COUNT = 'count',
  FIND_ONE = 'findOne',
  FIND_BY_ID = 'findById',
  FIND_ALL = 'findAll',
  PAGINATE = 'paginate',
  UPDATE = 'update',
  DELETE = 'delete',
  CREATE = 'create',
  CREATE_MANY = 'createMany'
}
export const ForgeModelResolverApi = BeanManager.register(
  'ForgeModelResolverClient',
  class {
    /**
     * @param {string} tableName
     * @param {string} id
     * @returns
     * @memberof ForgeResolverApi
     */
    findById(tableName: string, id: string, references: string[] = []) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.FIND_BY_ID,
        tableName,
        query: { id, references }
      });
    }
    /**
     *
     * @param {string} tableName
     * @param { {id?:string,filter:Function}} query
     * @returns
     */
    findOne(tableName: string, query: Record<string, any>) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.FIND_ONE,
        tableName,
        query
      });
    }
    /**
     * @param {string} tableName
     * @param {{id?:string,filter?:Function}} query
     * @returns
     */
    findAll(tableName: string, query = {}) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.FIND_ALL,
        tableName,
        query
      });
    }
    /**
     * @param {string} tableName
     * @param {{id?:string,filter?:Function}} query
     * @param {number} page
     * @param {number} limit
     */
    paginate(tableName: string, query = {}, page = 1, limit = 10) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.PAGINATE,
        tableName,
        query,
        page,
        limit
      });
    }
    /**
     * @param {string} tableName
     * @param {{id?:string,filter?:Function}} query
     * @returns
     */
    count(tableName: string, query = {}) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.COUNT,
        tableName,
        query
      });
    }
    /**
     * @param {string} tableName
     * @param {Record<string,any>} data
     * @returns Promise<Record<string,any>>
     */
    create(tableName: string, data: any) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.CREATE,
        tableName,
        data
      });
    }
    /**
     * @param {string} tableName
     * @param {Record<string,any>[]} data
     * @returns Promise<Record<string,any>[]>
     */
    createMany(tableName: string, data: any) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.CREATE_MANY,
        tableName,
        data
      });
    }
    /**
     * @param {string} tableName
     * @param {{id?:string,filter?:Function}} query
     * @param {Record<string,any>} data
     * @returns Promise<Record<string,any>>
     */
    update(tableName: string, query: Record<string, any>, data: any) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.UPDATE,
        tableName,
        query,
        data
      });
    }
    /**
     * @param {string} tableName
     * @param {{id?:string,filter?:Function}} query
     * @returns Promise<Record<string,any>>
     */
    delete(tableName: string, query: Record<string, any>) {
      return forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.DELETE,
        tableName,
        query
      });
    }
  }
);
