export class StringUtil {
  static getArrayItemByHash<T>(arr: T[], str: string) {
    const hash = this.generateHash(str, arr.length);
    return arr[hash];
  }
  static generateHash(str: string, maxValue: number) {
    let hash = 0;
    if (str.length == 0) return hash;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash) % maxValue;
  }
  matchWildCard(str, rule) {
    const escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
  }
}
