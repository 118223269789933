import { PanpalEventHandler } from '../../system/services/messaging/panpal.event.handler';
import { MondayApi } from '../../vendor/services/app.api';
const issueApi = MondayApi.instance();
export class IssueEventHandler implements PanpalEventHandler {
  constructor(private context: any) {}
  sortOrder = 0;
  getEventNames(): string[] {
    return [
      'issue.findById',
      'issue.findByJQL',
      'issue.findByFilter',
      'issue.findWithCommentsById'
    ];
  }

  async handle(event: string, payload: any) {
    console.log('issue', payload);
    switch (event) {
      case 'issue.findById':
        return issueApi.findIssueById(payload.id);
      case 'issue.findByJQL':
        return issueApi.findIssuesByJQL(payload);
      case 'issue.findByFilter':
        return issueApi.findIssueByFilter(payload);
      case 'issue.findWithCommentsById':
        return issueApi.findIssueWithCommentsById(payload);
    }
    return payload;
  }
}
