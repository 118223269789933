import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ITemplateEntity } from '../../../model/itemplate';
import { AppRuntime } from '../../../../vendor/services/app.runtime';
import { TemplateService } from '../../../services/template.service';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { FullScreen } from '../../../../system/components/fullscreen';
import {
  Button,
  Card,
  Checkbox,
  FormInstance,
  Modal,
  Result,
  Select,
  Skeleton,
  Space,
  Tabs,
  Tooltip,
  Tour,
  TourProps
} from 'antd';
import { Allotment } from 'allotment';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { NotificationService } from '../../../../system/services/ui/notification.service';
import { PermissionService } from '../../../services/csp.service';
import { CheckOutlined } from '@ant-design/icons';
import { Variable } from '../../variable-config/variable.form';
import VariableConfiguration from './VariableConfiguration';
import { useDebounce } from '../../../../system/hooks/useDebounce';
import { ReactIf } from '../../../../system/components/conditional/r.if';
import { OtherConfigurations } from './OtherConfigurations';
import { TemplateRenderer } from '../../admin/template/TemplateRenderer';
import { ITemplateConfig } from '../../../model/itemplate.config';
import { GtmService } from '../../../../system/services/stats/gtm.service';
import { TourService } from '../../../../system/services/ui/tour.service';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import {
  UserJourneyEvent,
  UserJourneyService
} from '../../../../system/services/ui/user.journey.service';
import { TemplateConfigService } from '../../../services/template.config.service';

export interface IConfigRendererProps {
  templateConfig: ITemplateConfig;
  onApplyConfig: (config) => any;
}

const runtime = AppRuntime.instance();
const notification = NotificationService.instance();
const templateService = TemplateService.instance();
const templateConfigService = TemplateConfigService.instance();
const permissionService = PermissionService.instance();
const tourService = TourService.instance();
const userJourney = UserJourneyService.instance();

export const TemplateConfiguration = forwardRef(function (
  { templateConfig: config, onApplyConfig }: IConfigRendererProps,
  ref
) {
  const [template, setTemplate] = useState<ITemplateEntity>(templateService.emptyTemplate());
  const [cspSettings, setCspSettings] = useState<any>({ enableCSP: false, policies: [] });
  const [templateConfig, setTemplateConfig] = useState<ITemplateConfig>(config || {});
  const [loading, setLoading] = useState({ templateLoading: false, settingsLoading: false });
  const [showLoading, setShowLoading] = useState(false); // show loading additionally
  const [templates, setTemplates] = useState<ITemplateEntity[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const variableFormRef = useRef<FormInstance<Variable>>();
  const otherConfigFormRef = useRef<FormInstance<ITemplateConfig>>();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const templateChangeDebounce = useDebounce(500);
  //const { width, height } = useWindowSize();
  const [openTour, setOpenTour] = useState(!tourService.isUserVisitedTour('template-config'));
  const tourRefs = {
    backBtn: useRef(null),
    editBtn: useRef(null),
    applyBtn: useRef(null),
    templateSelector: useRef(null),
    variableConfig: useRef(null),
    otherConfig: useRef(null),
    preview: useRef(null),
    templateConfig: useRef(null),
    includeExample: useRef(null),
    refreshButton: useRef(null)
  };
  const steps: TourProps['steps'] = [
    {
      title: 'Go Back',
      description: 'Click here to go back to the template view',
      target: tourRefs.backBtn.current
    },
    {
      title: 'Select Template',
      description: 'Select the template from the dropdown list',
      target: tourRefs.templateSelector.current
    },
    {
      title: 'Include Example',
      description: 'If you want to include example templates, check this box',
      target: tourRefs.includeExample.current
    },
    {
      title: 'Refresh',
      description: 'In case of some issues you can refresh the configuration page from here',
      target: tourRefs.refreshButton.current
    },
    {
      title: 'Edit Template',
      description: 'Click here to edit the template in a popup',
      target: tourRefs.editBtn.current
    },
    {
      title: 'Apply Template',
      description: 'Click here to apply the template on this view',
      target: tourRefs.applyBtn.current
    },
    {
      title: 'All Set!',
      description: (
        <div>
          <Title level={3}>Dive right in!</Title>
          <Paragraph>
            Choose a template from the dropdown to begin your creative journey. If it's your first
            time, fret not! You can craft a new template from our vibrant library or simply toggle
            on the &quotInclude examples&quot for a smooth start.
          </Paragraph>
        </div>
      ),
      target: tourRefs.templateSelector.current
    }
    // {
    //   title: 'Variable Configuration',
    //   description: 'Configure the variables',
    //   target: tourRefs.variableConfig.current
    // },
    // {
    //   title: 'Other Configurations',
    //   description: 'Configure other settings (e.g. Show/Hide config button)',
    //   target: tourRefs.otherConfig.current
    // },
    // {
    //   title: 'Preview',
    //   description: 'Preview the template',
    //   target: tourRefs.preview.current
    // }
  ];
  useImperativeHandle(ref, () => ({
    async getTemplateConfig() {
      const valid = await validateConfig();
      return { ...templateConfig, valid };
    }
  }));
  useAsyncEffect(async () => {
    GtmService.instance().pageview('/templates/configuration');
    setLoading((loading) => ({ ...loading, settingsLoading: true }));
    try {
      const [csps] = await Promise.all([permissionService.getContentPolicies()]);
      setCspSettings(csps);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((loading) => ({ ...loading, settingsLoading: false }));
    }
  }, []);
  useAsyncEffect(async () => {
    setShowLoading(true);
    try {
      const allTemplates = await templateService.getAllTemplates({
        includeExamples: templateConfig.includeExamples,
        published: true
      });
      setTemplates(
        allTemplates.filter((t) => typeof t.publishedAt !== 'undefined' || t.type === 'example')
      );
    } catch (e) {
      console.error(e);
    }
    setShowLoading(false);
  }, [templateConfig.includeExamples]);
  useAsyncEffect(async () => {
    if (!templateConfig.templateId || templateConfig.templateId === 'new') {
      return;
    }
    setLoading((loading) => ({ ...loading, templateLoading: true }));
    try {
      const configuredTemplate = await templateService.getTemplateById(
        templateConfig.templateId,
        true,
        {
          includeExamples: true
        }
      );
      setTemplate(configuredTemplate);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((loading) => ({ ...loading, templateLoading: false }));
    }
  }, [templateConfig.templateId]);
  useEffect(() => {
    if (
      loading.settingsLoading === false &&
      loading.templateLoading === false &&
      template.id === templateConfig.templateId
    ) {
      // check if template and settings has been loaded
      const variables = template.variables || [];
      const config: ITemplateConfig = templateConfig || {};
      if (!config.variables) {
        config.variables = {};
      }
      const variableConfig: Variable[] = variables.map((variable) => {
        let value = variable.defaultFieldValue;
        if (
          config.templateId === template.id &&
          typeof config.variables[variable.name] !== 'undefined'
        ) {
          value = config.variables[variable.name];
        }
        if (!variable.settings) {
          variable.settings = {};
        }
        return {
          ...variable,
          value
        };
      });
      setTemplate((template) => ({ ...template, variables: variableConfig }));
      setIsInitialized(true);
      if (variableFormRef.current) {
        variableFormRef.current.setFieldsValue(
          variableConfig.reduce((acc, variable) => {
            acc[variable.name] = variable.value;
            return acc;
          }, {})
        );
      }
    }
  }, [loading, templateConfig.templateId, templateConfig.templateId]);

  const getTemplateOptions = () => {
    if (templateConfig.includeExamples) {
      return templates.reduce(
        (acc, t) => {
          if (t.type === 'example') {
            acc[1].options.push({ label: t.name, value: t.id });
          } else {
            acc[0].options.push({ label: t.name, value: t.id });
          }
          return acc;
        },
        [
          { label: 'User Defined', options: [] },
          { label: 'Examples', options: [] }
        ]
      );
    }
    return [
      {
        label: 'User Defined',
        options: templates.map((t) => ({ label: t.name, value: t.id }))
      }
    ];
  };
  const validateConfig = async () => {
    try {
      await variableFormRef.current?.submit();
      await variableFormRef.current?.validateFields();
      await otherConfigFormRef.current?.submit();
      await otherConfigFormRef.current?.validateFields();
    } catch (e) {
      console.error(e);
      notification.error({
        key: 'saving',
        message: 'Please fix the errors before saving'
      });
      return false;
    }
    return true;
  };
  const applyTemplateConfig = async () => {
    if (!(await validateConfig())) {
      return;
    }
    modal.confirm({
      title: 'Are you sure?',
      icon: <i className="fa fa-cloud-upload" />,
      content: <div>Apply &quot{template.name}&quot Template on this view </div>,
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: async () => {
        notification.info({ key: 'saving', message: 'Saving configuration...' });
        try {
          await onApplyConfig({
            ...templateConfig,
            variables: template.variables.reduce((acc, variable) => {
              acc[variable.name] = variable.value;
              return acc;
            }, {})
          });
          //  await templateConfigService.saveIssueTemplateConfig();
          notification.success({
            key: 'saving',
            message: 'Configurations saved successfully'
          });
          GtmService.instance().pageview(`/templates/${template.name}/configured`);
          userJourney.logEvent(UserJourneyEvent.TEMPLATE_CONFIGURED, {
            templateId: template.id,
            templateName: template.name
          });
          // await navigate('/macro/render');
        } catch (e) {
          console.error(e);
          notification.error({
            key: 'saving',
            message: 'Failed to save Configurations, Please try again later'
          });
        }
      }
    });
  };
  return (
    <div className="template-configuration">
      {contextHolder}
      <Skeleton
        loading={loading.settingsLoading || loading.templateLoading || showLoading}
        active={true}
        paragraph={{ rows: 10 }}>
        <div className="menu-component" style={{ paddingBottom: '5px', display: 'flex' }}>
          <Space className="left-menu" style={{ display: 'flex' }}>
            <Tooltip title="Back to Template">
              <Button
                ref={tourRefs.backBtn}
                className="back-btn"
                type="link"
                size="large"
                icon={<i className="fa fa-arrow-circle-left"></i>}
                onClick={() => {
                  navigate('/macro/render');
                }}></Button>
            </Tooltip>
            <div className="template-select" ref={tourRefs.templateSelector}>
              <span className="title">Select Template </span>
              <Select
                labelInValue={true}
                style={{ minWidth: '100px', width: '180px' }}
                showSearch={true}
                placeholder="Search to Select"
                options={getTemplateOptions()}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                value={{ value: template.id, label: template.name }}
                onChange={(labelValue: { value: string; label: React.ReactNode }) => {
                  setTemplateConfig((config) => ({
                    ...config,
                    templateId: labelValue.value,
                    templateName: labelValue.label + ''
                  }));
                }}></Select>
            </div>
            <div className="include-example" ref={tourRefs.includeExample}>
              <Checkbox
                checked={templateConfig.includeExamples}
                onChange={(e) => {
                  setTemplateConfig((config) => ({
                    ...config,
                    includeExamples: e.target.checked
                  }));
                }}>
                Include Example
              </Checkbox>
            </div>
          </Space>
          <Space
            className="all-code-box-controls"
            style={{
              position: 'absolute',
              top: '5px',
              right: '10px',
              zIndex: 1,
              display: 'flex',
              fontSize: '22px'
            }}>
            <Tooltip title="Refresh">
              <Button
                ref={tourRefs.refreshButton}
                icon={<i className="fa fa-refresh"></i>}
                type="link"
                onClick={async () => {
                  window.location.reload();
                }}></Button>
            </Tooltip>
            <Button
              type="primary"
              ref={tourRefs.applyBtn}
              icon={<CheckOutlined />}
              disabled={!template.id}
              onClick={() => applyTemplateConfig()}>
              Apply
            </Button>
          </Space>
        </div>
        <ReactIf condition={!templateConfig.templateId || templateConfig.templateId === 'new'}>
          <Result
            status={404}
            title="Select a template from the dropdown to configure"
            subTitle="Only published templates can be configured."
          />
        </ReactIf>
        <ReactIf condition={!!template.id}>
          <div className="ide-body">
            <Allotment defaultSizes={[300, 200]}>
              <Allotment.Pane>
                <FullScreen>
                  <div className="template-configure-wrapper" style={{ padding: '10px' }}>
                    <Tabs
                      defaultActiveKey="1"
                      items={[
                        {
                          key: 'variables',
                          label: (
                            <div
                              className="tour-step variable-config"
                              ref={tourRefs.variableConfig}>
                              <Space>
                                <i className="fa fa-wrench" />
                                Variables
                              </Space>
                            </div>
                          ),
                          children: (
                            <ReactIf condition={isInitialized}>
                              <VariableConfiguration
                                variables={template.variables || []}
                                onSubmit={() => {}}
                                onInit={(form) => {
                                  variableFormRef.current = form;
                                }}
                                onChange={(variables) => {
                                  templateChangeDebounce(() => {
                                    setTemplate((template) => ({ ...template, variables }));
                                  });
                                }}
                              />
                            </ReactIf>
                          )
                        },
                        {
                          key: 'details',
                          label: (
                            <div className="tour-step other-config" ref={tourRefs.otherConfig}>
                              <Space>
                                <i className="fa fa-display" />
                                Display Settings
                              </Space>
                            </div>
                          ),
                          children: (
                            <ReactIf condition={isInitialized}>
                              <OtherConfigurations
                                template={template}
                                config={config}
                                onInit={(form) => {
                                  otherConfigFormRef.current = form;
                                }}
                                onChange={(otherConfig) => {
                                  setTemplateConfig((templateConfig) => ({
                                    ...templateConfig,
                                    ...otherConfig
                                  }));
                                }}
                              />
                            </ReactIf>
                          )
                        }
                      ]}></Tabs>
                  </div>
                </FullScreen>
              </Allotment.Pane>
              <Allotment.Pane>
                <div className="template-preview" ref={tourRefs.preview}>
                  <Card title="Preview" className="configure-card">
                    <FullScreen>
                      <TemplateRenderer
                        debounce={500}
                        template={template}
                        cspSettings={cspSettings}
                        config={templateConfig}
                      />
                    </FullScreen>
                  </Card>
                </div>
              </Allotment.Pane>
            </Allotment>
          </div>
        </ReactIf>
      </Skeleton>

      <Tour
        open={openTour}
        onFinish={() => {
          userJourney.logEvent(UserJourneyEvent.TOUR_COMPLETED, {
            tourId: 'template-config'
          });
          tourService.endTour('template-config');
          setOpenTour(false);
        }}
        onClose={() => {
          setOpenTour(false);
          tourService.endTour('template-config');
        }}
        steps={steps}
      />
    </div>
  );
});

TemplateConfiguration.displayName = 'TemplateConfiguration';
