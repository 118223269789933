import { Button, Drawer, FloatButton, Skeleton, Space, Tabs, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import TemplateList from './template/TemplateList';
import { TemplateLibrary } from './library/TemplateLibrary';
import SettingsComponent from './settings/SettingsComponent';
import { UserAclProvider } from '../../contexts';
import { AclService, Permissions, UserInfo } from '../../services/acl.service';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../../../../error-page';
import HelpComponent from './settings/HelpComponent';
import { AppRuntime } from '../../../vendor/services/app.runtime';
import { ThemeService } from '../../../system/services/ui/theme.service';
import { TemplateConfigList } from './template/TemplateConfigList';

const aclService = AclService.instance();
export default function AdminComponent(props) {
  const navigate = useNavigate();
  const [permission, setPermission] = useState<Permissions>(Permissions.admin); // by default editor
  const [userInfo, setUserInfo] = useState<UserInfo>({
    acl: { enabled: false, aclWeight: 0, permission: Permissions.admin },
    user: null
  });
  const [userInfoApiError, setUserInfoApiError] = useState(null); // [error, setError
  const [loading, setLoading] = useState(false);
  const [openHelpDrawer, setOpenHelpDrawer] = useState(false);

  const onChange = (key) => {
    // console.log(key);
  };
  useEffect(() => {
    aclService
      .getUserInfo()
      .then((info) => {
        setUserInfo(info);
        setPermission(aclService.getPermission());
      })
      .catch((e) => {
        console.error(e);
        setUserInfoApiError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const canAccessSettings = userInfoApiError
    ? true
    : aclService.isInitialized() && aclService.isAdmin();
  const items = [
    {
      key: 'templates',
      label: (
        <Space>
          <i className="fa fa-clipboard-list" />
          Templates
        </Space>
      ),
      children: <TemplateList />
    },
    {
      key: 'gallery',
      label: (
        <Space>
          <i className="fa fa-film" />
          Gallery
        </Space>
      ),
      children: <TemplateLibrary />
    },
    {
      key: 'settings',
      label: (
        <Space>
          <i className="fa fa-cog" />
          Settings
        </Space>
      ),
      children: canAccessSettings ? (
        <SettingsComponent />
      ) : (
        <ErrorPage
          showBackButton={false}
          error={{
            title: 'Unauthorized Access',
            status: 'error',
            message: 'You are not authorized to access this page.'
          }}
        />
      )
    },
    {
      key: 'Template Configurations',
      label: (
        <Space>
          <i className="fa fa-clipboard-check" />
          Configured Templates
        </Space>
      ),
      children: <TemplateConfigList />
    }
  ];
  AppRuntime.instance().adjustHeightBasedOnRootDiv();
  // document.body.style.setProperty('overflow', 'hidden', 'important');
  // document.documentElement.style.setProperty('overflow', 'hidden', 'important');
  //const maxHeight = window.screen.availHeight - 50 + 'px'; //htmlService.instance().getFullPageHeight(window.document) + 'px';
  //document.body.style.maxHeight = maxHeight;
  // document.documentElement.style.maxHeight = maxHeight;
  /**document.body.style.maxHeight = '600px';
  document.body.style.overflow = 'visible';
  document.documentElement.style.overflowY = 'visible';**/
  // ForgeRuntime.instance().setPageHeight('600px');
  return (
    <UserAclProvider.Provider value={{ userInfo, permission, userInfoApiError }}>
      <div className="admin-component">
        <div
          className="right-menu"
          style={{
            position: 'absolute',
            top: '10px',
            right: '5px',
            zIndex: 1000
          }}>
          <Space>
            <Tooltip title="Help">
              <Button
                style={{ fontSize: '20px', fontWeight: 'bold' }}
                type="link"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenHelpDrawer(!openHelpDrawer);
                }}>
                <i className="fa fa-question-circle" />
              </Button>
            </Tooltip>
          </Space>
          <Drawer
            title="Help"
            placement="right"
            width="400px"
            onClose={() => {
              setOpenHelpDrawer(false);
            }}
            open={openHelpDrawer}>
            <HelpComponent />
          </Drawer>
        </div>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </UserAclProvider.Provider>
  );
}
