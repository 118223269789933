import React, { SetStateAction, useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { AppRuntime } from './modules/vendor/services/app.runtime';
// import { invoke } from '@forge/bridge';
const mondayRuntime = AppRuntime.instance();
export function ForgeNavigator() {
  const [error, setError] = useState<null | Error>(null);
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  console.log('Navigating original route', pathname, search, hash);
  useEffect(() => {
    try {
      (async () => {
        // await forgeMacroService.instance().init();
        // await ForgeBridgeApi.instance().init();
        // invoke('storageModelCrud', {
        //   queryMethod: 'findAll',
        //   tableName: 'sys_tables'
        // }).then((data) => console.log(data));
        const context = await mondayRuntime.getContext();
        console.log(context, 'context variables');
        let route = '';
        if (await mondayRuntime.isRenderingInModal()) {
          route = await mondayRuntime.getModalRoute();
        }
        if (context.extension.modal) {
          route = context.extension.modal.route;
        } else {
          switch (context.extension.type) {
            case 'confluence:globalPage':
              route = '/admin-page';
              break;
            case 'confluence:globalSettings':
              route = '/admin-page';
              break;
            case 'jira:dashboardGadget':
              route = '/macro/render-dashboard-widget';
              break;
            default:
              route = '/admin-page';
              break;
          }
        }
        navigate(route, { state: { forgeContext: context } });
      })();
    } catch (e) {
      setError(e);
    }
  }, []);

  return <>{error ? error?.toString() : 'Please wait...'}</>;
}
