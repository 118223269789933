import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Checkbox,
  Button,
  Table,
  Space,
  Popconfirm,
  message,
  Row,
  Col,
  Switch,
  Tooltip,
  Descriptions,
  Card
} from 'antd';
import { PermissionService } from '../../../services/csp.service';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { NotificationService } from '../../../../system/services/ui/notification.service';
import { GtmService } from '../../../../system/services/stats/gtm.service';

const notification = NotificationService.instance();

const permissionService = PermissionService.instance();
// reference:https://content-security-policy.com/
export function CSPSettingsList() {
  const [policies, setPolicies] = useState([]);
  const [enableCSP, setEnableCSP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { policies, enableCSP } = await permissionService.getContentPolicies();
      if (typeof enableCSP !== 'undefined') {
        setPolicies(policies);
        setEnableCSP(enableCSP);
      }
      setLoading(false);
      GtmService.instance().pageview('/admin/permissions/csp');
    })();
  }, []);

  const handleAddPolicy = async (values) => {
    const updatedPolicies = [...policies, values];
    notification.loading({ message: 'Adding Policy...', key: 'adding', duration: 0 });
    try {
      await permissionService.saveContentPolicies({
        policies: updatedPolicies,
        enableCSP
      });
      // @ts-ignore
      setPolicies(updatedPolicies);
      form.resetFields();
      notification.success({ message: 'Policy added successfully', key: 'adding' });
    } catch (e) {
      notification.error({ message: 'Error adding policy', key: 'adding' });
    }
    setLoading(false);
  };

  const handleDeletePolicy = async (index) => {
    notification.loading({ message: 'Deleting Policy...', key: 'deleting', duration: 0 });
    const updatedPolicies = policies.filter((_, i) => i !== index);
    try {
      await permissionService.saveContentPolicies({
        policies: updatedPolicies,
        enableCSP
      });
      // @ts-ignore
      setPolicies(updatedPolicies);
      notification.success({ message: 'Policy deleted successfully', key: 'deleting' });
    } catch (e) {
      notification.error({ message: 'Error deleting policy', key: 'deleting' });
    }
  };

  // write a mapping of csp Directive with label and values
  const cspDirectiveMap = {
    'default-src': 'Default Source',
    'script-src': 'Script Source',
    'style-src': 'Style Source',
    'img-src': 'Image Source',
    'font-src': 'Font Source',
    'object-src': 'Object Source',
    'media-src': 'Media Source',
    'frame-src': 'Frame Source'
  };
  const columns = [
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url'
    },
    ...Object.keys(cspDirectiveMap).map((directive) => ({
      title: cspDirectiveMap[directive],
      dataIndex: directive,
      key: directive,
      render: (value) => (
        <Tooltip title={value ? 'Allowed' : 'Disallowed'} key={directive}>
          <span style={{ color: value === true ? 'green' : 'red' }}>
            <i className={value ? 'fa fa-check' : 'fa fa-x'} />
          </span>
        </Tooltip>
      )
    })),
    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => (
        <Space size="middle">
          <Popconfirm
            disabled={enableCSP === false}
            title="Are you sure to delete this policy?"
            onConfirm={() => handleDeletePolicy(index)}
            okText="Yes"
            cancelText="No">
            <a href="#">
              <i className="fa fa-trash" />
            </a>
          </Popconfirm>
        </Space>
      )
    }
  ];
  return (
    <div className="settings-wrapper">
      <Col span={24}>
        <Card
          loading={loading}
          title={
            <Space>
              <i className="fa fa-lock"></i>Content Security Policies
            </Space>
          }
          bordered={false}>
          <div className="csp-wrapper">
            <Row gutter={24}>
              <Col span={18}>
                <Card
                  loading={loading}
                  className="info"
                  title="Configuration"
                  extra={
                    <div className="extra">
                      <label>Enable CSP </label>
                      <Switch
                        checked={enableCSP}
                        onChange={async (value) => {
                          try {
                            notification.loading({
                              message: 'Updating...',
                              key: 'updating',
                              duration: 0
                            });
                            setEnableCSP(value);
                            await permissionService.saveContentPolicies({
                              policies,
                              enableCSP: value
                            });
                            notification.success({
                              message: 'CSP settings updated successfully',
                              key: 'updating'
                            });
                          } catch (e) {
                            console.error(e);
                            setEnableCSP(!value);
                            notification.error({
                              message: 'Error updating CSP settings',
                              key: 'updating'
                            });
                          }
                        }}></Switch>
                    </div>
                  }>
                  <Row gutter={24} style={{ marginBottom: '10px', padding: '5px' }}>
                    <Col span={24} className={enableCSP === false ? 'disabled' : ''}>
                      <Form
                        form={form}
                        onFinish={handleAddPolicy}
                        disabled={enableCSP === false}
                        layout="horizontal"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}>
                        <Form.Item
                          name="url"
                          label="Hostname Expression"
                          help="Enter a valid URL wildcard expression, e.g *.example.com"
                          rules={[
                            { required: true, message: 'Please input a valid URL expression' }
                          ]}>
                          <Input />
                        </Form.Item>
                        {/* <Form.Item label="Allow All" valuePropName="checked" name="all">
              <Checkbox></Checkbox>
            </Form.Item> */}
                        <Form.Item label="Allow">
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'stretch',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}>
                            {Object.keys(cspDirectiveMap).map((directive, index) => (
                              <Form.Item key={index} name={directive} valuePropName="checked">
                                <Checkbox>{cspDirectiveMap[directive]}</Checkbox>
                              </Form.Item>
                            ))}
                          </div>
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Add Policy
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  loading={loading}
                  className="info"
                  title="What is CSP?"
                  extra={
                    <Button
                      type="link"
                      onClick={() => {
                        window.open('https://content-security-policy.com', '_blank');
                      }}>
                      Know More
                    </Button>
                  }>
                  Content-Security-Policy is the name of a HTTP response header that modern browsers
                  use to enhance the security of the document (or web page). The
                  Content-Security-Policy header allows you to restrict which resources (such as
                  JavaScript, CSS, Images, etc.) can be loaded, and the URLs that they can be loaded
                  from.
                </Card>
              </Col>
            </Row>
            <Table rowKey="url" dataSource={policies} columns={columns} />
          </div>
        </Card>
      </Col>
    </div>
  );
}
