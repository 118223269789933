export enum ResolverApiRoutes {
  storageModelCrud = 'storage.model.crud',
  templateCrud = 'html.template.crud',
  userInfo = 'html.template.user.info',

  getAllProjectKeys = 'jira.project.property.getAllProjectKeys',
  getProjectProperty = 'jira.project.property.getProjectProperty',
  getProjectProperties = 'jira.project.property.getProjectProperties',
  setProjectProperty = 'jira.project.property.setProjectProperty',
  deleteProjectProperty = 'jira.project.property.deleteProjectProperty',

  getIssueTemplateConfig = 'html.template.issue.getConfig',
  saveIssueTemplateConfig = 'html.template.issue.saveConfig',
  deleteIssueTemplateConfig = 'html.template.issue.deleteConfig',
  getProjectTemplateConfig = 'html.template.project.getConfig',
  saveProjectTemplateConfig = 'html.template.project.saveConfig',
  deleteProjectTemplateConfig = 'html.template.project.deleteConfig'
}
