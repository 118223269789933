import { Input, Switch, Select } from 'antd';
import { SelectOptionsRenderer } from './renderers/select.options.renderer';
import { Variable } from './variable.form';
import { CodeEditor } from '../editor/CodeEditor';
import { useMemo } from 'react';
const { TextArea } = Input;
export type VariableType =
  | 'string'
  | 'number'
  // | 'date'
  | 'boolean'
  | 'choice'
  | 'multichoice'
  | 'json'
  | 'multilineString'
  | 'script';
export interface ComponentConfig {
  component: any;
  props?: Record<string, any> | ((variable: Variable) => Record<string, any>);
  label?: string;
  [key: string]: any;
}
export const VariableTypeComponentsMapping: Record<string, ComponentConfig> = {
  string: { component: Input, props: { label: 'String', allowClear: true } },
  multilineString: { component: TextArea, props: { label: 'String', allowClear: true, rows: 2 } },
  number: { component: Input, props: { label: 'Number', allowClear: true, type: 'number' } },
  // date: { component: Input, props: { label: 'Date' } },
  boolean: { component: Switch, props: { label: 'Boolean' } },
  choice: { component: Select, props: { label: 'Choice' } },
  json: {
    label: 'JSON',
    component: CodeEditor,
    props: (variable: Variable) => {
      return {
        height: 100,
        style: { border: '1px solid #d9d9d9', borderRadius: '2px' },
        label: 'JSON',
        allowClear: true,
        language: 'json'
      };
    }
  },
  script: {
    label: 'Script', // shnow in variable form under type select
    component: CodeEditor,
    props: (variable: Variable, options: any) => {
      return {
        style: { border: '1px solid #d9d9d9', borderRadius: '2px' },
        height: options?.height || 100,
        allowClear: true,
        defaultLanguage: variable.settings.language || 'javascript'
      };
    }
  },
  multichoice: {
    component: Select,
    props: { label: 'Multi Choice', mode: 'multiple' }
  }
};
export interface FormItemByTypeRendererProps {
  variable: Variable;
  value?: any;
  onChange?: (value: any) => void;
  [key: string]: any;
}
export function FormItemByTypeRenderer({
  variable,
  value,
  onChange,
  ...options
}: FormItemByTypeRendererProps) {
  let renderType = variable.type;
  if (!VariableTypeComponentsMapping[renderType]) {
    renderType = 'string';
  }
  let Component = useMemo(
    () => VariableTypeComponentsMapping[renderType].component || Input,
    [variable.type]
  );
  if (variable.settings.multiLine) {
    Component = TextArea;
  }
  const props: any = useMemo(() => {
    let cachedProps: any = VariableTypeComponentsMapping[renderType].props;
    if (typeof cachedProps === 'function') {
      cachedProps = cachedProps(variable, options);
    }
    if (renderType === 'multichoice') {
      if (cachedProps.value && !Array.isArray(cachedProps.value)) {
        cachedProps.value = [cachedProps.value];
      }
    }
    if (renderType === 'choice' || renderType === 'multichoice') {
      cachedProps.options = variable.choices?.map((op, index) => ({
        label: op?.label || index,
        value: op?.value || index
      }));
    }
    return cachedProps;
  }, [variable.type, variable.choices, variable.settings]);
  return (
    <Component
      key={variable.name}
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
      {...props}
    />
  );
}
