import { ResolverApiRoutes } from '../../../api.routes';
import { BeanManager } from '../../system/services/system/bean.manager';
import { TableNames } from '../model/table.names';
import { ReflectionUtil } from '../../system/utils/reflection.util';
import { ITemplateEntity } from '../model/itemplate';
import { AppRuntime } from '../../vendor/services/app.runtime';
import { getExampleTemplateById } from '../templates/index';
import { UserJourneyService } from '../../system/services/ui/user.journey.service';
import { ForgeResolverApi } from '../../forge/api/forge.resolver.api';
import { QueryMethod } from '../../forge/api/forge.model.resolver.api';

const forgeResolverApi = ForgeResolverApi.instance();
export const TemplateTableName = TableNames.HtmlTemplate;
const TemplateQueryMethod = {
  GET_EXAMPLES: 'getExamples',
  GET_ALL: 'getAll'
};
export const TagColors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple'
];
const runtime = AppRuntime.instance();
const userJourney = UserJourneyService.instance();

export const TemplateService = BeanManager.register(
  class TemplateService {
    generateHtmlId(templateId: string) {
      return `html:${templateId}`;
    }
    generatePHtmlId(templateId: string) {
      return `phtml:${templateId}`;
    }

    emptyTemplate(): ITemplateEntity {
      return {
        name: '',
        description: '',
        html: '<!-- HTML -->',
        css: '/** CSS */',
        javascript: '/** JavaScript */',
        active: true,
        htmlId: null,
        cssId: null,
        javascriptId: null,
        variables: [],
        type: 'user_defined'
      };
    }
    constructor() {}
    getTagColor(tagName: string) {
      return TagColors[(tagName.charCodeAt(0) % TagColors.length) - 1];
    }
    serialize(template: ITemplateEntity): any {
      if (template.html) {
        template.$htmlId = { value: template.html };
      }
      if (template.css) {
        template.$cssId = { value: template.css };
      }
      if (template.javascript) {
        template.$javascriptId = { value: template.javascript };
      }
      template = {
        ...template,
        html: null,
        css: null,
        javascript: null
      };
      return template;
    }
    unserialize(template: ITemplateEntity, published = false): ITemplateEntity {
      if (published && template.type !== 'example') {
        template.html = template.$phtmlId?.value;
        template.css = template.$pcssId?.value;
        template.javascript = template.$pjavascriptId?.value;
        template.variables = template.pvariables;
      } else {
        template.html = template.$htmlId?.value;
        template.css = template.$cssId?.value;
        template.javascript = template.$javascriptId?.value;
      }
      return template;
    }
    async deleteTemplate(template: ITemplateEntity) {
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.DELETE,
        tableName: TemplateTableName,
        query: { id: template.id, references: ['javascriptId', 'cssId', 'htmlId'] }
      });
      return this.unserialize(response.contents);
    }
    async getAllExamples(): Promise<ITemplateEntity[]> {
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.templateCrud, {
        queryMethod: TemplateQueryMethod.GET_EXAMPLES
      });
      return response.contents || [];
    }
    async getAllTemplates(
      query: { includeExamples?: boolean; published?: boolean } = {}
    ): Promise<ITemplateEntity[]> {
      let examples = [];
      if (query.includeExamples) {
        examples = await this.getAllExamples();
      }
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.FIND_ALL,
        tableName: TemplateTableName,
        query
      });
      let userTemplates = (response.contents || []).map((template) => {
        return this.unserialize(template);
      });
      if (query.published) {
        userTemplates = userTemplates.filter((template) => {
          return template.publishedAt;
        });
      }
      return userTemplates.concat(examples);
    }
    // This can return both published and unpublished templates based on the published flag
    async getTemplateById(
      id: string,
      published = false,
      { includeExamples = true } = {}
    ): Promise<ITemplateEntity> {
      if (includeExamples) {
        const examples = await getExampleTemplateById(id, true);
        if (examples) {
          return ReflectionUtil.clone(examples);
        }
      }
      let references = ['javascriptId', 'cssId', 'htmlId'];
      if (published) {
        references = references.map((ref) => {
          return `p${ref}`;
        });
      }
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.templateCrud, {
        queryMethod: QueryMethod.FIND_BY_ID,
        tableName: TemplateTableName,
        query: { id, references }
      });
      if (published && (!response.contents || !response.contents.publishedAt)) {
        return null;
      }
      return this.unserialize(response.contents, published);
    }
    async updateTemplate(template: ITemplateEntity, detailsOnly = false): Promise<ITemplateEntity> {
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.UPDATE,
        tableName: TemplateTableName,
        query: {
          id: template.id,
          references: detailsOnly ? [] : ['javascriptId', 'cssId', 'htmlId']
        },
        data: this.serialize(template)
      });
      if (response.contents) {
        const template = response.contents;
        return this.unserialize(template);
      }
      return response.contents;
    }
    async createTemplate(template: ITemplateEntity): Promise<ITemplateEntity> {
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.storageModelCrud, {
        queryMethod: QueryMethod.CREATE,
        tableName: TemplateTableName,
        query: { references: ['javascriptId', 'cssId', 'htmlId'] },
        data: this.serialize(template)
      });
      if (response.contents) {
        const template = response.contents;
        return this.unserialize(template);
      }
      return response.contents;
    }
    async upSertTemplate(template: ITemplateEntity): Promise<ITemplateEntity> {
      if (template.id) {
        return await this.updateTemplate(template);
      }
      return await this.createTemplate(template);
    }
    async publishTemplate(templateId: string): Promise<ITemplateEntity> {
      const response = await forgeResolverApi.invoke(ResolverApiRoutes.templateCrud, {
        queryMethod: 'publish',
        tableName: TemplateTableName,
        query: { id: templateId }
      });
      return this.unserialize(response.contents, true);
    }
  }
);
