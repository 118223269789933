export default {
  name: 'Facebook Embed',
  description: 'Embed any type of Facebook post on a webpage.',
  category: 'Social Media / Facebook',
  tags: ['facebook', 'social media'],
  active: true,
  icon: 'fa-brands fa-facebook',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<div id="fb-root"></div>
    <div id="facebook-post"></div>`,
  script: `/** JavaScript */
  $template.ready(async () => {
    const variables = $template.useVariables();
    const postUrl = await variables.getValue('Facebook_Post_URL');
    const postId = getFacebookPostId(postUrl);

    if (postId) {
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0';
      scriptElement.id = 'facebook-jssdk';
      scriptElement.setAttribute('crossorigin', 'anonymous');
      document.body.appendChild(scriptElement);

      // Wait for the Facebook SDK to load
      window.fbAsyncInit = function() {
        FB.init({
          xfbml: true,
          version: 'v12.0'
        });

        // Create the embedded post
        const embeddedPost = document.createElement('div');
        embeddedPost.className = 'fb-post';
        embeddedPost.setAttribute('data-href', postUrl);
        embeddedPost.setAttribute('data-show-text', 'true');
        embeddedPost.setAttribute('data-width', 'auto');

        // Append the embedded post to the designated container
        const container = document.getElementById('facebook-post');
        container.appendChild(embeddedPost);
      };
    } else {
      // Invalid URL handling
      console.error('Invalid Facebook Post URL:', postUrl);
    }
  });
 
  function getFacebookPostId(url) {
    const regex = new RegExp('//([^/]+)/?$/');
    const match = url.match(regex);
    return match ? match[1] : url;
  }`,
  css: ``,
  variables: [
    {
      label: 'Facebook Post URL',
      name: 'Facebook_Post_URL',
      type: 'multilineString',
      defaultFieldValue: 'https://www.facebook.com/20531316728/posts/10154009990506729/',
      mandatory: true,
      description: 'Facebook Post URL',
      defaultVariable: true
    }
  ]
};
