import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Spin, Tooltip } from 'antd';
import { EditOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined />;
export interface InlineEditProps {
  value?: string;
  allowEdit?: boolean;
  onChange?: (value: string) => void;
  onSave?: (value: string) => void;
  placeholder?: string;
  [key: string]: any;
  style?: React.CSSProperties;
}
export function InlineEdit({
  value,
  placeholder,
  onChange,
  onSave,
  allowEdit,
  style,
  ...props
}: InlineEditProps) {
  onChange = onChange || function () {};
  onSave = onSave || function () {};

  const [mode, setMode] = useState('view');
  const [editValue, setEditValue] = useState(value);
  const [loading, setLoading] = useState(false);
  /**@type {any}*/
  const inputRef = useRef<any>();
  const onSubmit = async () => {
    setMode('view');
    setLoading(true);
    try {
      if (editValue !== value) {
        await onSave(editValue);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    setEditValue(value);
  }, [value]);
  useEffect(() => {
    if (mode === 'edit') {
      inputRef.current?.focus();
    }
  }, [mode]);
  if (mode == 'view') {
    return (
      <div className="view">
        <Spin indicator={antIcon} spinning={loading}>
          <span>{editValue}</span>
          <Tooltip title="edit">
            <Button
              style={{ display: allowEdit ? 'inline-block' : 'none' }}
              size="small"
              type="link"
              onClick={() => {
                setMode('edit');
              }}
              icon={<EditOutlined />}></Button>
          </Tooltip>
        </Spin>
      </div>
    );
  } else {
    return (
      <div className="edit" style={{ display: 'flex' }}>
        {/* <Input.Group compact={true}> */}
        <Input
          ref={inputRef}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setMode('view');
            }
            e.stopPropagation(); // fix for ant tab
          }}
          size="small"
          value={editValue}
          placeholder={placeholder}
          style={style}
          onChange={(e) => {
            setEditValue(e.target.value);
            onChange(e.target.value);
          }}
          onPressEnter={() => onSubmit()}
          onBlur={() => onSubmit()}
          {...props}
        />
        <Tooltip title="save">
          <Button
            type="link"
            size="small"
            onClick={() => onSubmit()}
            icon={<CheckCircleOutlined />}></Button>
        </Tooltip>
        {/* </Input.Group> */}
      </div>
    );
  }
}
InlineEdit.displayName = 'InlineEdit';
InlineEdit.propTypes = {
  value: PropTypes.string,
  allowEdit: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  placeholder: PropTypes.string
};
