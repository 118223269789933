import { KeyValueStorage } from '../entities/key.value.entity';
import { AppRuntime } from './app.runtime';
import { BeanManager } from '../../system/services/system/bean.manager';
import { ITemplateConfig } from '../../advance-html/model/itemplate.config';

const runtime = AppRuntime.instance();
export type ViewInstanceType =
  | 'item_view'
  | 'board_view'
  | 'dashboard'
  | 'notification'
  | 'workspace'
  | 'account'
  | 'user'
  | 'document'
  | 'unknown'
  | 'account_settings_view';
interface ConfigTargetObject {
  instanceType: ViewInstanceType;
  id: string;
}
export const MondayContextService = BeanManager.register(
  class MondayContextService {
    constructor() {}
    async getViewObject(): Promise<ConfigTargetObject> {
      const context = await runtime.getContext();
      const instanceType = context.extension.instanceType;
      const id = context.extension.id;
      return {
        instanceType,
        id
      };
    }
  }
);
