import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IframeRenderer from '../../editor/IframeRenderer';
import { TemplateService } from '../../../services/template.service';
import {
  Button,
  Card,
  Col,
  FloatButton,
  Result,
  Row,
  Skeleton,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import {
  PlusCircleFilled,
  DeleteFilled,
  EyeOutlined,
  ThunderboltOutlined,
  LoadingOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { ITemplateEntity } from '../../../model/itemplate';
import { VariableEventHandler } from '../../../panpal-handlers/variable.event.handler';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { ConfigValidationResult, VariableService } from '../../../services/variable.service';
import { Variable } from '../../variable-config/variable.form';
import { ReactIf } from '../../../../system/components/conditional/r.if';
import { CodeSnippet } from '../../../../system/components/utils/code.snippet';
import { useNavigate, useParams } from 'react-router-dom';
const { Text, Link } = Typography;
const { Title } = Typography;
import { GtmService } from '../../../../system/services/stats/gtm.service';
import { PermissionService } from '../../../services/csp.service';
import { AppRuntime } from '../../../../vendor/services/app.runtime';
import { CSPConfig } from 'antd/es/config-provider';
import { ITemplateConfig } from '../../../model/itemplate.config';

const variableService = VariableService.instance();
const permissionService = PermissionService.instance();

const mondayRuntime = AppRuntime.instance();
export interface ITemplateRendererProps {
  template?: ITemplateEntity;
  config?: ITemplateConfig;
  cspSettings?: any;
  id?: string;
  debounce?: number;
  onConfigButtonClick?: () => any;
}
const isMandatoryVariableConfigured = (result: ConfigValidationResult) => {
  for (const rec of result) {
    if (rec.mandatory && !rec.configured) {
      return false;
    }
  }
  return true;
};
export function TemplateRenderer(props: ITemplateRendererProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [configResult, setConfigResult] = useState<ConfigValidationResult>([]);
  const [templateLoadingError, setTemplateLoadingError] = useState<Error>(null);
  const [insideMacro, setInsideMacro] = useState(false);
  const [cspSettings, setCSPSettings] = useState(
    props.cspSettings || { enabled: false, policies: [] }
  );
  const [template, setTemplate] = useState<ITemplateEntity>(
    props.template || TemplateService.instance().emptyTemplate()
  );
  const [mandatoryConfigured, setMandatoryConfigured] = useState(true);
  let { id } = useParams<{ id: string }>();
  if (!id) {
    id = props.id;
  }
  useEffect(() => {
    if (props.template) {
      setTemplate(props.template);
    }
  }, [props.template]);
  useAsyncEffect(async () => {
    if (!template?.id) return; // template not loaded yet
    const result = await variableService.validateVariables(template, props.config);
    setConfigResult(result);
    const inMacro = await AppRuntime.instance().isRenderringAsMacro();
    setInsideMacro(inMacro);
    GtmService.instance().pageview(`/template/render/${template.name}`);
    await AppRuntime.instance().updateUIStyle(props.config);
    // height of the template should be set here after loading and rendering template
    // this is to avoid iframe height issue
  }, [template]);

  useAsyncEffect(async () => {
    if (!template?.id && id) {
      setLoading(true);
      try {
        const [template] = await Promise.all([
          TemplateService.instance().getTemplateById(id, true)
        ]);
        setTemplate(template);
      } catch (e) {
        setTemplateLoadingError(e);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);
  useAsyncEffect(async () => {
    if (!props.cspSettings) {
      const csp = await permissionService.getContentPolicies();
      setCSPSettings(csp);
    }
  }, [props.cspSettings]);

  useAsyncEffect(async () => {
    if (!template?.id) return; // template not loaded yet
    const mandatoryVarConfigured = insideMacro ? isMandatoryVariableConfigured(configResult) : true;
    setMandatoryConfigured(mandatoryVarConfigured);
    setTimeout(async () => {
      await AppRuntime.instance().updateUIStyle(props.config);
      if (mandatoryVarConfigured && template?.id) {
        //
      } else {
        AppRuntime.instance().showFullBody();
      }
    }, 100);
  }, [props.config, template.variables]);
  return (
    <div className="template-renderer">
      <Skeleton loading={loading} active>
        <ReactIf condition={!!templateLoadingError}>
          <Result
            status="500"
            title="Template has been deleted or does not exist"
            subTitle={
              <div>
                Please click on
                <Button
                  type="link"
                  icon={<i className="fa fa-cog"></i>}
                  onClick={() => {
                    // mondayRuntime.openFeatureModal();
                  }}>
                  settings
                </Button>
                icon on top right corner and configure the template.
              </div>
            }></Result>
        </ReactIf>
        <ReactIf condition={!templateLoadingError}>
          <ReactIf condition={mandatoryConfigured}>
            <Tooltip title="Configure">
              <FloatButton
                className="configure-template-btn"
                style={{
                  display: props.config.enableConfigAction ? 'block' : 'none'
                }}
                onClick={() => {
                  props?.onConfigButtonClick();
                }}
                icon={<i className="fa fa-cog"></i>}
              />
            </Tooltip>
            <IframeRenderer
              cspSettings={cspSettings}
              autoExecute={true}
              template={template}
              debounce={props.debounce || 0}
              bodyStyle={props.config.styleSettings}></IframeRenderer>
          </ReactIf>
          <ReactIf condition={!mandatoryConfigured}>
            <div className="template-config-error">
              <Result
                style={{ height: '90%' }}
                status="error"
                title="Mandatory variables not configured."
                subTitle="While configuring the Template ,please fill values for all mandatory variables.">
                <Card title="Variables">
                  <div className="variable-list">
                    <div
                      className="variable-configuration-tip"
                      style={{
                        padding: '10px',
                        marginBottom: '10px',
                        overflow: 'scroll'
                      }}>
                      <p>
                        <Tag color="error">Mandatory</Tag> Variables must be configured before the
                        page can be rendered.
                      </p>
                      <p>
                        <Tag color="warning">Optional</Tag> Variables are not mandatory, but if
                        configured, they will be used to render the page.
                      </p>
                    </div>
                    <Table dataSource={configResult} pagination={false} rowKey="name">
                      <Table.Column title="Variable Name" dataIndex="name" key="name" width={250} />
                      <Table.Column
                        title="Mandatory"
                        dataIndex="mandatory"
                        width={80}
                        key="mandatory"
                        render={(value, record) => {
                          return value ? (
                            <Tag color="error">Yes</Tag>
                          ) : (
                            <Tag color="warning">No</Tag>
                          );
                        }}
                      />
                      <Table.Column
                        title="Configured"
                        dataIndex="configured"
                        width={80}
                        key="configured"
                        render={(value, record) => {
                          return value ? (
                            <Tag color="success">Yes</Tag>
                          ) : (
                            <Tag color="warning">No</Tag>
                          );
                        }}
                      />
                      <Table.Column title="Description" dataIndex="description" key="description" />

                      <Table.Column title="Value" dataIndex="value" key="value" />
                    </Table>
                  </div>
                </Card>
              </Result>
            </div>
          </ReactIf>
        </ReactIf>
      </Skeleton>
    </div>
  );
}
